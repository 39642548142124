import { ToastState } from '@platform-ui-kit/components-library/components'

import { defaultTheme } from '../components/gantt/gantt'
import { EventDelta } from '../components/gantt/task-gantt-content'
import { BarTask, Coordinates, Milestones } from '../types/bar-task'
import { BarMoveAction } from '../types/gantt-task-actions'
import { TimeObject, Task, Parity } from '../types/public-types'

export const convertToBarTasks = (
  tasks: Task[],
  dates: Date[],
  columnWidth: number,
  barCornerRadius: number,
  handleWidth: number,
  selectedTask: BarTask | undefined,
  isAdmin: boolean,
  showToast: (config: ToastState) => void,
) => {
  return tasks.map((t, i) => {
    return convertToBarTask(
      tasks,
      t,
      i,
      dates,
      columnWidth,
      barCornerRadius,
      handleWidth,
      selectedTask,
      isAdmin,
      showToast,
    )
  })
}

const convertToBarTask = (
  tasks: Task[],
  task: Task,
  index: number,
  dates: Date[],
  columnWidth: number,
  barCornerRadius: number,
  handleWidth: number,
  selectedTask: BarTask | undefined,
  isAdmin: boolean,
  showToast: (config: ToastState) => void,
): BarTask => {
  let barTask: BarTask

  try {
    barTask = convertToBar(tasks, task, index, dates, columnWidth, barCornerRadius, handleWidth, selectedTask, isAdmin)
    return barTask
  } catch (e) {
    console.log('error converting task to bar', e)
    showToast({
      type: 'error',
      message: `There was an error displaying task: ${task.name}. Please check the console for more details.`,
    })
    task.migrationMilestone = []
    task.adoptionMilestone = []
    task.convergenMilestone = []
    task.parities = []
    task.features = []
    task.products = []
    return convertToBar(tasks, task, index, dates, columnWidth, barCornerRadius, handleWidth, selectedTask, isAdmin)
  }
}

const convertToBar = (
  tasks: Task[],
  task: Task,
  index: number,
  dates: Date[],
  columnWidth: number,
  barCornerRadius: number,
  handleWidth: number,
  selectedTask: BarTask | undefined,
  isAdmin: boolean,
): BarTask => {
  const x1 = taskXCoordinate(task.start, dates, columnWidth)
  const x2 = taskXCoordinate(task.end, dates, columnWidth)
  const taskHeight = getTaskHeight(task, selectedTask, isAdmin)
  const y = taskYCoordinate(index, taskHeight, tasks, selectedTask, isAdmin)

  let milestones: Milestones = {
    convergence: milestonesToCoordinates(task.convergenMilestone, dates, columnWidth),
    adoption: milestonesToCoordinates(task.adoptionMilestone, dates, columnWidth),
    migration: milestonesToCoordinates(task.migrationMilestone, dates, columnWidth),
  }

  const featuresX = milestonesToCoordinates(task.features, dates, columnWidth)
  const productsX = milestonesToCoordinates(task.products, dates, columnWidth)
  const paritiesX = parityToCoordinates(task.parities, dates, columnWidth)

  return {
    ...task,
    x1,
    x2,
    y,
    index,
    barCornerRadius,
    handleWidth,
    height: taskHeight,
    milestones,
    featuresX,
    productsX,
    paritiesX,
  }
}

const milestonesToCoordinates = (
  benefitsMilestones: TimeObject[],
  dates: Date[],
  columnWidth: number,
): Coordinates[] => {
  let coordinates: Coordinates[] = []
  for (let milestone of benefitsMilestones) {
    coordinates.push({
      x1: taskXCoordinate(milestone.start, dates, columnWidth),
      x2: taskXCoordinate(milestone.end, dates, columnWidth),
      name: milestone.name,
    })
  }
  return coordinates
}

const parityToCoordinates = (benefitsMilestones: Parity[], dates: Date[], columnWidth: number): Coordinates[] => {
  let coordinates: Coordinates[] = []
  for (let milestone of benefitsMilestones) {
    coordinates.push({
      x1: taskXCoordinate(milestone.date, dates, columnWidth),
      x2: taskXCoordinate(milestone.date, dates, columnWidth),
      name: milestone.name,
    })
  }
  return coordinates
}

export const taskXCoordinate = (xDate: Date, dates: Date[], columnWidth: number) => {
  const index = dates.findIndex(d => d.getTime() >= xDate.getTime()) - 1

  const remainderMillis = xDate.getTime() - dates[index].getTime()
  const percentOfInterval = remainderMillis / (dates[index + 1].getTime() - dates[index].getTime())
  return index * columnWidth + percentOfInterval * columnWidth
}

const taskYCoordinate = (
  index: number,
  taskHeight: number,
  tasks: Task[],
  selectedTask: BarTask | undefined,
  isAdmin: boolean,
) => {
  let rowsBeforeHeight = 0
  for (let i = 0; i < index; i++) {
    rowsBeforeHeight += getRowHeight(tasks[i], selectedTask, isAdmin)
  }
  return rowsBeforeHeight + (getRowHeight(tasks[index], selectedTask, isAdmin) - taskHeight) / 2
}

const startByX = (x: number, xStep: number, task: BarTask) => {
  if (x >= task.x2 - task.handleWidth * 2) {
    x = task.x2 - task.handleWidth * 2
  }
  const steps = Math.round((x - task.x1) / xStep)
  const additionalXValue = steps * xStep
  return task.x1 + additionalXValue
}

const endByX = (x: number, xStep: number, task: BarTask) => {
  if (x <= task.x1 + task.handleWidth * 2) {
    x = task.x1 + task.handleWidth * 2
  }
  const steps = Math.round((x - task.x2) / xStep)
  const additionalXValue = steps * xStep
  return task.x2 + additionalXValue
}

const moveByX = (x: number, xStep: number, x1: number, x2: number) => {
  const steps = Math.round((x - x1) / xStep)
  const additionalXValue = steps * xStep
  const newX1 = x1 + additionalXValue
  const newX2 = newX1 + x2 - x1
  return [newX1, newX2]
}

const dateByX = (x: number, taskX: number, taskDate: Date, xStep: number, timeStep: number) => {
  let newDate = new Date(((x - taskX) / xStep) * timeStep + taskDate.getTime())
  newDate = new Date(newDate.getTime() + (newDate.getTimezoneOffset() - taskDate.getTimezoneOffset()) * 60000)
  return newDate
}

function setXmove(
  changedCoordinates: Coordinates[],
  selectedCoordinates: Coordinates[],
  changedTimeObject: TimeObject[],
  selectedTimeObject: TimeObject[],
  svgX: number,
  xStep: number,
  timeStep: number,
  eventDeltaX: number[],
) {
  for (let i = 0; i < changedCoordinates.length; i++) {
    const selected = structuredClone(selectedCoordinates[i])
    const changed = changedCoordinates[i]
    const [moveX1, moveX2] = moveByX(svgX - eventDeltaX[i], xStep, selected.x1, selected.x2)
    changed.x1 = moveX1
    changed.x2 = moveX2
    const changedTime = changedTimeObject[i]
    const selectedTime = structuredClone(selectedTimeObject[i])
    changedTime.start = dateByX(moveX1, selected.x1, selectedTime.start, xStep, timeStep)
    changedTime.end = dateByX(moveX2, selected.x2, selectedTime.end, xStep, timeStep)
  }
}

/**
 * Method handles event in real time(mousemove) and on finish(mouseup)
 */
export const handleTaskBySVGMouseEvent = (
  svgX: number,
  action: BarMoveAction,
  selectedTask: BarTask,
  xStep: number,
  timeStep: number,
  initEventX1Delta: EventDelta,
): { isChanged: boolean; changedTask: BarTask } => {
  let result: { isChanged: boolean; changedTask: BarTask }
  result = handleTaskBySVGMouseEventForBar(svgX, action, selectedTask, xStep, timeStep, initEventX1Delta)
  return result
}

const handleTaskBySVGMouseEventForBar = (
  svgX: number,
  action: BarMoveAction,
  selectedTask: BarTask,
  xStep: number,
  timeStep: number,
  eventDelta: EventDelta,
): { isChanged: boolean; changedTask: BarTask } => {
  const changedTask: BarTask = { ...selectedTask }
  let isChanged = false
  switch (action) {
    case 'start': {
      const newX1 = startByX(svgX, xStep, selectedTask)
      changedTask.x1 = newX1
      isChanged = changedTask.x1 !== selectedTask.x1
      if (isChanged) {
        changedTask.start = dateByX(newX1, selectedTask.x1, selectedTask.start, xStep, timeStep)
      }
      break
    }
    case 'end': {
      const newX2 = endByX(svgX, xStep, selectedTask)
      changedTask.x2 = newX2
      isChanged = changedTask.x2 !== selectedTask.x2
      if (isChanged) {
        changedTask.end = dateByX(newX2, selectedTask.x2, selectedTask.end, xStep, timeStep)
      }
      break
    }
    case 'move': {
      const [newMoveX1, newMoveX2] = moveByX(svgX - eventDelta.project, xStep, selectedTask.x1, selectedTask.x2)
      isChanged = newMoveX1 !== selectedTask.x1
      if (isChanged) {
        changedTask.start = dateByX(newMoveX1, selectedTask.x1, selectedTask.start, xStep, timeStep)
        changedTask.end = dateByX(newMoveX2, selectedTask.x2, selectedTask.end, xStep, timeStep)
        changedTask.x1 = newMoveX1
        changedTask.x2 = newMoveX2
        setXmove(
          changedTask.milestones.convergence,
          selectedTask.milestones.convergence,
          changedTask.convergenMilestone,
          selectedTask.convergenMilestone,
          svgX,
          xStep,
          timeStep,
          eventDelta.convergence,
        )
        setXmove(
          changedTask.milestones.adoption,
          selectedTask.milestones.adoption,
          changedTask.adoptionMilestone,
          selectedTask.adoptionMilestone,
          svgX,
          xStep,
          timeStep,
          eventDelta.adoption,
        )
        setXmove(
          changedTask.milestones.migration,
          selectedTask.milestones.migration,
          changedTask.migrationMilestone,
          selectedTask.migrationMilestone,
          svgX,
          xStep,
          timeStep,
          eventDelta.migration,
        )
        setXmove(
          changedTask.featuresX,
          selectedTask.featuresX,
          changedTask.features,
          selectedTask.features,
          svgX,
          xStep,
          timeStep,
          eventDelta.features,
        )
        setXmove(
          changedTask.productsX,
          selectedTask.productsX,
          changedTask.products,
          selectedTask.products,
          svgX,
          xStep,
          timeStep,
          eventDelta.products,
        )
      }
      break
    }
  }
  return { isChanged, changedTask }
}

export const getTaskHeight = (task: Task, selectedTask: BarTask | undefined, isAdmin: boolean) => {
  if (selectedTask?.id === task.id) {
    return (
      (defaultTheme.rowHeight * defaultTheme.barFill) / 100 +
      (task.products.length + (isAdmin ? 1 : 0)) * 32 +
      (task.features.length + (isAdmin ? 1 : 0)) * 32
    )
  }
  return (defaultTheme.rowHeight * defaultTheme.barFill) / 100
}

export const getRowHeight = (task: Task, selectedTask: BarTask | undefined, isAdmin: boolean) => {
  if (selectedTask?.id === task.id) {
    return (
      defaultTheme.rowHeight +
      (task.products.length + (isAdmin ? 1 : 0)) * 32 +
      (task.features.length + (isAdmin ? 1 : 0)) * 32
    )
  }
  return defaultTheme.rowHeight
}
