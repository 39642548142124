import React from 'react'

import { BarDisplay } from './bar-display'
import styles from './bar.module.scss'
import { TaskItemProps } from '../task-item'

export const Bar: React.FC<TaskItemProps> = ({ task, isDateChangeable, onEventStart, isSelected, modalProps }) => {
  return (
    <g className={styles.barWrapper} tabIndex={0}>
      <BarDisplay
        task={task}
        isSelected={isSelected}
        onMouseDown={e => {
          isDateChangeable && onEventStart('move', task, e)
        }}
        modalProps={modalProps}
      />
    </g>
  )
}
