import React, { useEffect, useState } from 'react'

import { handleTaskBySVGMouseEvent } from '../../helpers/bar-helper'
import { isKeyboardEvent } from '../../helpers/other-helper'
import { BarTask } from '../../types/bar-task'
import { BarMoveAction, GanttContentMoveAction, GanttEvent } from '../../types/gantt-task-actions'
import { EventOption } from '../../types/public-types'
import { AddItemModalProps } from '../task-item/bar/bar-display'
import { TaskItem, UpdateBenefitModalProps } from '../task-item/task-item'

export interface EventDelta {
  project: number
  features: number[]
  products: number[]
  convergence: number[]
  adoption: number[]
  migration: number[]
}

export type TaskGanttContentProps = {
  tasks: BarTask[]
  dates: Date[]
  ganttEvent: GanttEvent
  selectedTask: BarTask | undefined
  rowHeight: number
  columnWidth: number
  timeStep: number
  svg?: React.RefObject<SVGSVGElement>
  svgWidth: number
  arrowColor: string
  arrowIndent: number
  fontSize: number
  fontFamily: string
  setGanttEvent: (value: GanttEvent) => void
  setFailedTask: (value: BarTask | null) => void
  setSelectedTask: (taskId: string) => void
  modalProps: AddItemModalProps
  updateBenefitModalProps: UpdateBenefitModalProps
} & EventOption

export const TaskGanttContent: React.FC<TaskGanttContentProps> = ({
  tasks,
  dates,
  ganttEvent,
  selectedTask,
  columnWidth,
  timeStep,
  svg,
  fontFamily,
  fontSize,
  setGanttEvent,
  setFailedTask,
  setSelectedTask,
  onDateChange,
  onProgressChange,
  onDoubleClick,
  onClick,
  onDelete,
  modalProps,
  updateBenefitModalProps,
}) => {
  const point = svg?.current?.createSVGPoint()
  const [xStep, setXStep] = useState(0)
  const [initEventX1Delta, setInitEventX1Delta] = useState<EventDelta>({
    project: 0,
    features: [],
    products: [],
    convergence: [],
    adoption: [],
    migration: [],
  })
  const [isMoving, setIsMoving] = useState(false)

  // create xStep
  useEffect(() => {
    const dateDelta =
      dates[1].getTime() -
      dates[0].getTime() -
      dates[1].getTimezoneOffset() * 60 * 1000 +
      dates[0].getTimezoneOffset() * 60 * 1000
    const newXStep = (timeStep * columnWidth) / dateDelta
    setXStep(newXStep)
  }, [columnWidth, dates, timeStep])

  useEffect(() => {
    const handleMouseMove = async (event: MouseEvent) => {
      if (!ganttEvent.changedTask || !point || !svg?.current) return
      event.preventDefault()

      point.x = event.clientX
      const cursor = point.matrixTransform(svg?.current.getScreenCTM()?.inverse())

      const { isChanged, changedTask } = handleTaskBySVGMouseEvent(
        cursor.x,
        ganttEvent.action as BarMoveAction,
        ganttEvent.changedTask,
        xStep,
        timeStep,
        initEventX1Delta,
      )
      if (isChanged) {
        setGanttEvent({ action: ganttEvent.action, changedTask })
      }
    }

    const handleMouseUp = async (event: MouseEvent) => {
      const { action, originalSelectedTask, changedTask } = ganttEvent
      if (!changedTask || !point || !svg?.current || !originalSelectedTask) return
      event.preventDefault()

      point.x = event.clientX
      const cursor = point.matrixTransform(svg?.current.getScreenCTM()?.inverse())
      const { changedTask: newChangedTask } = handleTaskBySVGMouseEvent(
        cursor.x,
        action as BarMoveAction,
        changedTask,
        xStep,
        timeStep,
        initEventX1Delta,
      )

      const isNotLikeOriginal =
        originalSelectedTask.start !== newChangedTask.start || originalSelectedTask.end !== newChangedTask.end

      // remove listeners
      svg.current.removeEventListener('mousemove', handleMouseMove)
      svg.current.removeEventListener('mouseup', handleMouseUp)
      setGanttEvent({ action: '' })
      setIsMoving(false)

      // custom operation start
      let operationSuccess = true
      if ((action === 'move' || action === 'end' || action === 'start') && onDateChange && isNotLikeOriginal) {
        try {
          const result = await onDateChange(newChangedTask)
          if (result !== undefined) {
            operationSuccess = result
          }
        } catch (error) {
          operationSuccess = false
        }
      } else if (onProgressChange && isNotLikeOriginal) {
        try {
          const result = await onProgressChange(newChangedTask)
          if (result !== undefined) {
            operationSuccess = result
          }
        } catch (error) {
          operationSuccess = false
        }
      }

      // If operation is failed - return old state
      if (!operationSuccess) {
        setFailedTask(originalSelectedTask)
      }
    }

    if (
      !isMoving &&
      (ganttEvent.action === 'move' ||
        ganttEvent.action === 'end' ||
        ganttEvent.action === 'start' ||
        ganttEvent.action === 'progress') &&
      svg?.current
    ) {
      svg.current.addEventListener('mousemove', handleMouseMove)
      svg.current.addEventListener('mouseup', handleMouseUp)
      setIsMoving(true)
    }
  }, [
    ganttEvent,
    initEventX1Delta,
    isMoving,
    onDateChange,
    onProgressChange,
    point,
    setFailedTask,
    setGanttEvent,
    svg,
    timeStep,
    xStep,
  ])

  /**
   * Method is Start point of task change
   */
  const handleBarEventStart = async (
    action: GanttContentMoveAction,
    task: BarTask,
    event?: React.MouseEvent | React.KeyboardEvent,
  ) => {
    if (!event) {
      if (action === 'select') {
        setSelectedTask(task.id ?? '-1')
      }
    }
    // Keyboard events
    else if (isKeyboardEvent(event)) {
      if (action === 'delete') {
        if (onDelete) {
          try {
            const result = await onDelete(task)
            if (result !== undefined && result) {
              setGanttEvent({ action, changedTask: task })
            }
          } catch (error) {
            console.error('Error on Delete. ' + error)
          }
        }
      }
    }
    // Mouse Events
    else if (action === 'mouseenter') {
      if (!ganttEvent.action) {
        if (!svg?.current || !point) return
        point.x = event.clientX
        const cursor = point.matrixTransform(svg.current.getScreenCTM()?.inverse())
        setInitEventX1Delta({
          project: cursor.x - task.x1,
          features: task.featuresX.map(feature => cursor.x - feature.x1),
          products: task.productsX.map(product => cursor.x - product.x1),
          convergence: task.milestones.convergence.map(convergence => cursor.x - convergence.x1),
          adoption: task.milestones.adoption.map(adoption => cursor.x - adoption.x1),
          migration: task.milestones.migration.map(migration => cursor.x - migration.x1),
        })
        setGanttEvent({
          action,
          changedTask: task,
          originalSelectedTask: task,
        })
      }
    } else if (action === 'mouseleave') {
      if (ganttEvent.action === 'mouseenter') {
        setGanttEvent({ action: '' })
      }
    } else if (action === 'dblclick') {
      !!onDoubleClick && onDoubleClick(task)
    } else if (action === 'click') {
      !!onClick && onClick(task)
    }
    // Change task event start
    else if (action === 'move') {
      if (!svg?.current || !point) return
      point.x = event.clientX
      const cursor = point.matrixTransform(svg.current.getScreenCTM()?.inverse())
      setInitEventX1Delta({
        project: cursor.x - task.x1,
        features: task.featuresX.map(feature => cursor.x - feature.x1),
        products: task.productsX.map(product => cursor.x - product.x1),
        convergence: task.milestones.convergence.map(convergence => cursor.x - convergence.x1),
        adoption: task.milestones.adoption.map(adoption => cursor.x - adoption.x1),
        migration: task.milestones.migration.map(migration => cursor.x - migration.x1),
      })
      setGanttEvent({
        action,
        changedTask: task,
        originalSelectedTask: task,
      })
    } else {
      setGanttEvent({
        action,
        changedTask: task,
        originalSelectedTask: task,
      })
    }
  }

  return (
    <g className="content">
      <g className="bar" fontFamily={fontFamily} fontSize={fontSize}>
        {tasks.map(task => {
          return (
            <TaskItem
              task={task}
              isProgressChangeable={!!onProgressChange && !task.isDisabled}
              isDateChangeable={!!onDateChange && !task.isDisabled}
              isDelete={!task.isDisabled}
              onEventStart={handleBarEventStart}
              key={task.id}
              isSelected={!!selectedTask && task.id === selectedTask.id}
              isFocus={!selectedTask || (!!selectedTask && task.id === selectedTask.id)}
              modalProps={modalProps}
              updateBenefitModalProps={updateBenefitModalProps}
            />
          )
        })}
      </g>
    </g>
  )
}
