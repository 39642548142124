import { WppActionButton, WppPopover, WppTypography } from '@platform-ui-kit/components-library-react'
import { PropsWithChildren, useCallback, useEffect, useRef } from 'react'

import { useDeleteBenefit } from '../../api/benefits/mutations/useDeleteBenefit'
import { useDeleteFeatureOrProduct } from '../../api/benefits/mutations/useDeleteFeatureOrProduct'
import { queryClient } from '../../app/Root'
import { ApiQueryKeys } from '../../constants/apiQueryKeys'
import { useToast } from '../../hooks/useToast'
import { TimeObject } from '../gantt/src/types/public-types'
import styles from '../modals/modals.module.scss'

interface AddBenefitProps extends PropsWithChildren {
  name: string
  obj: TimeObject
}

export const DeletePopover: React.FC<AddBenefitProps> = ({ name, obj, children }) => {
  const defaultPopoverRef = useRef<HTMLWppPopoverElement>(null)
  const cancelButton = useRef<any>(null)
  const deleteButton = useRef<any>(null)

  const handleCloseButtonClick = () => {
    defaultPopoverRef?.current?.closePopover()
  }

  const { showToast } = useToast()
  const { mutateAsync: deleteBenefit } = useDeleteBenefit()
  const { mutateAsync: deleteFeatureOrProduct } = useDeleteFeatureOrProduct()

  const handleDelete = useCallback(async () => {
    if (!obj.id) {
      return
    }
    try {
      if (name === 'Benefit') {
        await deleteBenefit({ id: obj.id })
      } else {
        await deleteFeatureOrProduct({ id: obj.id })
      }
      await queryClient.invalidateQueries([ApiQueryKeys.ALL_BENEFITS])
      showToast({
        type: 'success',
        message: `${name} deleted successfully.`,
      })
      defaultPopoverRef?.current?.closePopover()
    } catch (e) {
      let message = `Couldn't delete ${name}. Check console for details.`
      showToast({
        type: 'error',
        message,
      })
      console.error(e)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const cancel = cancelButton.current
    const del = deleteButton.current
    cancel.addEventListener('click', handleCloseButtonClick)
    del.addEventListener('click', handleDelete)
    return () => {
      cancel.removeEventListener('click', handleCloseButtonClick)
      cancel.removeEventListener('click', handleDelete)
    }
  })

  return (
    <WppPopover
      config={{ appendTo: () => document.querySelector('#root')! }} // This config is required for React to work with different handlers like 'onClick'
      ref={defaultPopoverRef}
      closable
    >
      <div slot="trigger-element">{children}</div>
      <div className={styles.deleteContainer}>
        <WppTypography type="m-strong" tag="h5">
          Delete {name}?
        </WppTypography>
        <WppTypography type="s-body" tag="span" className={styles.deleteBody}>
          Are you sure you wish to delete {obj.name}? This cannot be undone.
        </WppTypography>
        <div className={styles.deleteButtons}>
          <WppActionButton
            variant="secondary"
            className={styles.secondaryButton}
            ref={cancelButton}
            onClick={handleCloseButtonClick}
          >
            Cancel
          </WppActionButton>
          <WppActionButton onClick={handleDelete} ref={deleteButton} variant="destructive">
            Delete
          </WppActionButton>
        </div>
      </div>
    </WppPopover>
  )
}
