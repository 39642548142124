// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c9Yri{-webkit-user-select:none;user-select:none;pointer-events:none;fill:var(--wpp-grey-color-000);text-anchor:middle;-webkit-touch-callout:none}.ErWsi{stroke:#e6e4e4}.PBZBh{-webkit-user-select:none;user-select:none;pointer-events:none;fill:var(--wpp-grey-color-1000);text-anchor:middle;-webkit-touch-callout:none}.eVrNh{fill:#fff}.wPPiy{fill:var(--wpp-primary-color-400)}", "",{"version":3,"sources":["webpack://./src/components/gantt/src/components/calendar/calendar.module.scss"],"names":[],"mappings":"AAAA,OACE,wBAAA,CAAA,gBAAA,CACA,mBAAA,CACA,8BAAA,CACA,kBAAA,CACA,0BAAA,CAGF,OACE,cAAA,CAGF,OACE,wBAAA,CAAA,gBAAA,CACA,mBAAA,CACA,+BAAA,CACA,kBAAA,CACA,0BAAA,CAGF,OACE,SAAA,CAGF,OACE,iCAAA","sourcesContent":[".calendarBottomText {\n  user-select: none;\n  pointer-events: none;\n  fill: var(--wpp-grey-color-000);\n  text-anchor: middle;\n  -webkit-touch-callout: none;\n}\n\n.calendarTopTick {\n  stroke: #e6e4e4;\n}\n\n.calendarTopText {\n  user-select: none;\n  pointer-events: none;\n  fill: var(--wpp-grey-color-1000);\n  text-anchor: middle;\n  -webkit-touch-callout: none;\n}\n\n.calendarHeader {\n  fill: #ffffff;\n}\n\n.bottomHeader {\n  fill: var(--wpp-primary-color-400);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendarBottomText": "c9Yri",
	"calendarTopTick": "ErWsi",
	"calendarTopText": "PBZBh",
	"calendarHeader": "eVrNh",
	"bottomHeader": "wPPiy"
};
export default ___CSS_LOADER_EXPORT___;
