import { WppButton, WppDivider, WppIconTrash, WppModal, WppTypography } from '@platform-ui-kit/components-library-react'

import { updateBenefitAPI } from './helpers/api-helpers'
import styles from './modals.module.scss'
import { useEditBenefit } from '../../api/benefits/mutations/useEditBenefit'
import { queryClient } from '../../app/Root'
import { ApiQueryKeys } from '../../constants/apiQueryKeys'
import { useToast } from '../../hooks/useToast'
import { Task } from '../gantt/src'

export interface SaveChangesProps {
  open: boolean
  setOpen: (open: boolean) => void
  changedBenefits: Task[]
  oldBenefits: Task[]
  handleDiscardChangedBenefit: (id?: string) => void
}

export const SaveChangesModal: React.FC<SaveChangesProps> = ({
  open,
  setOpen,
  changedBenefits,
  oldBenefits,
  handleDiscardChangedBenefit,
}) => {
  const { showToast } = useToast()
  const { mutateAsync: editBenefit } = useEditBenefit()

  const handleSave = async () => {
    for (let benefit of changedBenefits) {
      if (!benefit.id) {
        continue
      }
      const errorMessage = `Couldn't update ${benefit.name}. Check console for details.`
      const successMessage = `Benefit ${benefit.name} updated successfully.`
      await updateBenefitAPI({
        editBenefit,
        benefitId: benefit.id,
        newBenefit: benefit,
        errorMessage,
        successMessage,
        showToast,
      })
    }
    await queryClient.invalidateQueries([ApiQueryKeys.ALL_BENEFITS])
    setOpen(false)
  }

  const handleDeleteCheck = (id?: string) => {
    handleDiscardChangedBenefit(id)
  }

  return (
    <WppModal
      open={open}
      size="m"
      className={styles.modal}
      onWppModalClose={() => setOpen(false)}
      onWppModalOpen={() => setOpen(true)}
    >
      <h3 slot="header">Benefits date changes</h3>
      <div slot="body">
        <div className={styles.benefitList}>
          {changedBenefits.map(benefit => (
            <div key={benefit.id}>
              <div className={styles.benefit}>
                <div>
                  <WppTypography type="l-strong" tag="h5" className={styles.block}>
                    {benefit.name}
                  </WppTypography>
                  <WppTypography type="s-body" tag="span" className={styles.block}>
                    - Start date changed from{' '}
                    {oldBenefits.find(oldBenefit => oldBenefit.id === benefit.id)?.start?.toLocaleDateString() ??
                      'unknown'}{' '}
                    to {benefit.start.toLocaleDateString()}
                  </WppTypography>
                  <WppTypography type="s-body" tag="span" className={styles.block}>
                    - End date changed from{' '}
                    {oldBenefits.find(oldBenefit => oldBenefit.id === benefit.id)?.end?.toLocaleDateString() ??
                      'unknown'}{' '}
                    to {benefit.end.toLocaleDateString()}
                  </WppTypography>
                </div>
                <div className={styles.delete}>
                  <WppIconTrash
                    height={35}
                    width={35}
                    onClick={() => handleDeleteCheck(benefit.id)}
                    className={styles.deleteIcon}
                  />
                </div>
              </div>
              <WppDivider />
            </div>
          ))}
        </div>
      </div>
      <div slot="actions" className={styles.actions}>
        <WppButton variant="secondary" size="s" onClick={() => setOpen(false)}>
          Cancel
        </WppButton>
        <WppButton variant="primary" size="s" onClick={handleSave}>
          Save
        </WppButton>
      </div>
    </WppModal>
  )
}
