import { WppButton, WppIconTick, WppModal, WppTypography } from '@platform-ui-kit/components-library-react'
import { useEffect, useState } from 'react'

import styles from './modals.module.scss'
import { TimeObject } from '../gantt/src/types/public-types'

export interface AddItemProps {
  open: boolean
  setOpen: (open: boolean) => void
  itemName: string
  benefitName: string
  item: TimeObject
}

export const ViewItemModal: React.FC<AddItemProps> = ({ open, setOpen, itemName, benefitName, item }) => {
  const [form, setForm] = useState<TimeObject>(item)

  useEffect(() => {
    setForm(item)
  }, [item])

  return (
    <WppModal
      open={open}
      size="s"
      className={styles.modal}
      onWppModalClose={() => setOpen(false)}
      onWppModalOpen={() => setOpen(true)}
    >
      <h3 slot="header">{itemName}</h3>
      <div slot="body">
        <WppTypography type="s-body" tag="span" className={styles.block}>
          {form.description}
        </WppTypography>
        <WppTypography type="s-strong" tag="h5" className={styles.name}>
          Benefit
        </WppTypography>
        <WppTypography type="s-body" tag="span" className={styles.block}>
          {benefitName}
        </WppTypography>
        <WppTypography type="s-strong" tag="h5" className={styles.name}>
          Dates
        </WppTypography>
        <WppTypography type="s-body" tag="span" className={styles.block}>
          {form.start.toLocaleDateString()} - {form.end.toLocaleDateString()}
        </WppTypography>
        <WppTypography type="s-strong" tag="h5" className={styles.name}>
          Checklist
        </WppTypography>
        <div className={styles.name}>
          {form.checks?.map(check => (
            <div className={styles.checklistItem}>
              {check.check ? <WppIconTick width={20} height={20} /> : <div style={{ width: '20px' }} />}
              <WppTypography type="s-body" tag="span" style={{ opacity: check.check ? 0.5 : 1 }}>
                {check.name}
              </WppTypography>
            </div>
          ))}
        </div>
      </div>
      <div slot="actions" className={styles.actions}>
        <WppButton variant="primary" size="s" onClick={() => setOpen(false)}>
          Close
        </WppButton>
      </div>
    </WppModal>
  )
}
