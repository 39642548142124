// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iQodn {\n  background: #fff;\n  padding: 12px;\n  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);\n}\n\n.VaNJI {\n  font-size: 12px;\n  margin-bottom: 6px;\n  color: #666;\n}\n\n.SAV3t {\n  position: absolute;\n  display: flex;\n  flex-shrink: 0;\n  pointer-events: none;\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  user-select: none;\n}\n\n.Ag10I {\n  visibility: hidden;\n  position: absolute;\n  display: flex;\n  pointer-events: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/gantt/src/components/other/tooltip.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,wEAAwE;AAC1E;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,cAAc;EACd,oBAAoB;EACpB,2BAA2B;EAC3B,yBAAyB;EAGzB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,oBAAoB;AACtB","sourcesContent":[".tooltipDefaultContainer {\n  background: #fff;\n  padding: 12px;\n  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);\n}\n\n.tooltipDefaultContainerParagraph {\n  font-size: 12px;\n  margin-bottom: 6px;\n  color: #666;\n}\n\n.tooltipDetailsContainer {\n  position: absolute;\n  display: flex;\n  flex-shrink: 0;\n  pointer-events: none;\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n\n.tooltipDetailsContainerHidden {\n  visibility: hidden;\n  position: absolute;\n  display: flex;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipDefaultContainer": "iQodn",
	"tooltipDefaultContainerParagraph": "VaNJI",
	"tooltipDetailsContainer": "SAV3t",
	"tooltipDetailsContainerHidden": "Ag10I"
};
export default ___CSS_LOADER_EXPORT___;
