import { BarTask } from '../types/bar-task'
import { Task } from '../types/public-types'

export function isKeyboardEvent(
  event: React.MouseEvent | React.KeyboardEvent | React.FocusEvent,
): event is React.KeyboardEvent {
  return (event as React.KeyboardEvent).key !== undefined
}

export function isMouseEvent(
  event: React.MouseEvent | React.KeyboardEvent | React.FocusEvent,
): event is React.MouseEvent {
  return (event as React.MouseEvent).clientX !== undefined
}

export function isBarTask(task: Task | BarTask): task is BarTask {
  return (task as BarTask).x1 !== undefined
}

export const sortTasks = (taskA: Task, taskB: Task) => {
  const orderA = taskA.displayOrder || Number.MAX_VALUE
  const orderB = taskB.displayOrder || Number.MAX_VALUE
  if (orderA > orderB) {
    return 1
  } else if (orderA < orderB) {
    return -1
  } else {
    return 0
  }
}
