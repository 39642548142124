import { WppDatepicker, WppInput } from '@platform-ui-kit/components-library-react'

import { TaskWithNoDates } from 'components/gantt/src/types/public-types'

import styles from '../modals.module.scss'

interface BenefitProps {
  item: TaskWithNoDates
  setForm: React.Dispatch<React.SetStateAction<TaskWithNoDates>>
}

export const Benefit: React.FC<BenefitProps> = ({ item, setForm }) => {
  return (
    <div>
      <WppInput
        className={styles.name}
        name="Benefit name"
        labelConfig={{ text: 'Benefit name' }}
        placeholder="Benefit name here"
        value={item.name}
        onWppChange={e =>
          setForm(prevState => ({
            ...prevState,
            name: e.target.value,
          }))
        }
        required
      />
      <div className={styles.dates}>
        <WppDatepicker
          key={item?.start?.toDateString() + 'start'} // TODO remove when state change triggers rerender (duplicated key warning will be gone)
          labelConfig={{ text: 'Date From' }}
          className={styles.datepicker}
          value={item.start?.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) ?? ''}
          onWppChange={e =>
            setForm(prevState => ({
              ...prevState,
              start: e.detail.date as Date,
            }))
          }
          required
        />
        <WppDatepicker
          key={item?.end?.toDateString() + 'end'} // TODO remove when state change triggers rerender (duplicated key warning will be gone)
          labelConfig={{ text: 'Date To' }}
          className={styles.datepicker}
          value={item.end?.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) ?? ''}
          onWppChange={e => {
            setForm(prevState => ({
              ...prevState,
              end: e.detail.date as Date,
            }))
          }}
          required
        />
      </div>
    </div>
  )
}
