// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hA3CH{padding:46px 38px 0}.xS7YY{padding-bottom:24px}.vbLj3{display:flex;justify-content:space-between}.sZzpw{display:block}.l_SA2{display:flex;align-items:center;justify-content:center;min-width:300px;height:100%;min-height:300px}.tdOq8{display:flex;gap:12px}", "",{"version":3,"sources":["webpack://./src/pages/gantt/GanttPage.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA,CAGF,OACE,mBAAA,CAGF,OACE,YAAA,CACA,6BAAA,CAGF,OACE,aAAA,CAGF,OACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,WAAA,CACA,gBAAA,CAGF,OACE,YAAA,CACA,QAAA","sourcesContent":[".container {\n  padding: 46px 38px 0;\n}\n\n.title {\n  padding-bottom: 24px;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n\n.button {\n  display: block;\n}\n\n.emptyGant {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-width: 300px;\n  height: 100%;\n  min-height: 300px;\n}\n\n.buttonGroup {\n  display: flex;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "hA3CH",
	"title": "xS7YY",
	"header": "vbLj3",
	"button": "sZzpw",
	"emptyGant": "l_SA2",
	"buttonGroup": "tdOq8"
};
export default ___CSS_LOADER_EXPORT___;
