import React, { useState, SyntheticEvent, useRef, useEffect } from 'react'

import styles from './gantt.module.css'
import { TaskGantt } from './task-gantt'
import { TaskGanttContentProps } from './task-gantt-content'
import { useAdminContext } from '../../../../../app/AdminContext'
import { useToast } from '../../../../../hooks/useToast'
import { convertToBarTasks, getRowHeight } from '../../helpers/bar-helper'
import { ganttDateRange, seedDates } from '../../helpers/date-helper'
import { sortTasks } from '../../helpers/other-helper'
import { BarTask } from '../../types/bar-task'
import { DateSetup } from '../../types/date-setup'
import { GanttEvent } from '../../types/gantt-task-actions'
import { ViewMode, GanttProps } from '../../types/public-types'
import { CalendarProps } from '../calendar/calendar'
import { GridProps } from '../grid/grid'
import { HorizontalScroll } from '../other/horizontal-scroll'
import { StandardTooltipContent, Tooltip } from '../other/tooltip'
import { VerticalScroll } from '../other/vertical-scroll'

export const defaultTheme = {
  barColor: 'var(--wpp-dataviz-color-cat-dark-9)',
  milestoneColor: 'var(--wpp-primary-color-400)',
  milestoneParityColor: 'var(--wpp-primary-color-300)',
  featureColor: 'var(--wpp-grey-color-000)',
  featureStrokeColor: 'var(--wpp-dataviz-color-cat-neutral-4)',
  newFeatureColor: 'var(--wpp-dataviz-color-cat-light-4)',
  productColor: 'var(--wpp-grey-color-000)',
  productStrokeColor: 'var(--wpp-dataviz-color-cat-neutral-8)',
  newProductColor: 'var(--wpp-dataviz-color-cat-light-8)',
  headerHeight: 50,
  todayHeight: 50,
  columnWidth: 90,
  listCellWidth: '155px',
  rowHeight: 104,
  ganttHeight: 0,
  preStepsCount: 2,
  locale: 'en-GB',
  barFill: 88.5,
  barCornerRadius: 3,
  handleWidth: 8,
  timeStep: 300000,
  arrowColor: 'grey',
  fontFamily: 'Inter',
  fontSize: 14,
  arrowIndent: 20,
  todayColor: 'rgba(252, 248, 227, 0.5)',
}

export const Gantt: React.FunctionComponent<GanttProps> = ({
  tasks,
  timeStep = 300000,
  viewMode = ViewMode.Day,
  viewDate,
  TooltipContent = StandardTooltipContent,
  onDateChange,
  onProgressChange,
  onDoubleClick,
  onClick,
  onDelete,
  onSelect,
  onExpanderClick,
  modalProps,
  updateBenefitModalProps,
}) => {
  const {
    barColor,
    headerHeight,
    todayHeight,
    columnWidth,
    listCellWidth,
    rowHeight,
    ganttHeight,
    preStepsCount,
    locale,
    barCornerRadius,
    handleWidth,
    arrowColor,
    fontFamily,
    fontSize,
    arrowIndent,
  } = defaultTheme
  const isAdmin = useAdminContext()
  const { showToast } = useToast()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const taskListRef = useRef<HTMLDivElement>(null)
  const [dateSetup, setDateSetup] = useState<DateSetup>(() => {
    const [startDate, endDate] = ganttDateRange(tasks, viewMode, preStepsCount)
    return { viewMode, dates: seedDates(startDate, endDate, viewMode) }
  })
  const [currentViewDate, setCurrentViewDate] = useState<Date | undefined>(undefined)

  const [taskListWidth, setTaskListWidth] = useState(0)
  const [svgContainerWidth, setSvgContainerWidth] = useState(0)
  const [svgContainerHeight, setSvgContainerHeight] = useState(ganttHeight)
  const [barTasks, setBarTasks] = useState<BarTask[]>([])
  const [ganttEvent, setGanttEvent] = useState<GanttEvent>({
    action: '',
  })

  const [selectedTask, setSelectedTask] = useState<BarTask>()
  const [failedTask, setFailedTask] = useState<BarTask | null>(null)

  const svgWidth = dateSetup.dates.length * columnWidth

  const [scrollY, setScrollY] = useState(0)
  const [scrollX, setScrollX] = useState(-1)
  const [ignoreScrollEvent, setIgnoreScrollEvent] = useState(false)

  const [ganttFullHeight, setGanttFullHeight] = useState(0)
  useEffect(() => {
    setGanttFullHeight(barTasks.reduce((acc, t) => acc + getRowHeight(t, selectedTask, isAdmin), 0) + todayHeight)
  }, [barTasks, isAdmin, selectedTask, todayHeight])

  // task change events
  useEffect(() => {
    const filteredTasks = tasks.sort(sortTasks)
    const [startDate, endDate] = ganttDateRange(filteredTasks, viewMode, preStepsCount)
    let newDates = seedDates(startDate, endDate, viewMode)
    setDateSetup({ dates: newDates, viewMode })
    setBarTasks(
      convertToBarTasks(
        filteredTasks,
        newDates,
        columnWidth,
        barCornerRadius,
        handleWidth,
        selectedTask,
        isAdmin,
        showToast,
      ),
    )
    // eslint-disable-next-line
  }, [
    tasks,
    viewMode,
    preStepsCount,
    barCornerRadius,
    columnWidth,
    handleWidth,
    barColor,
    scrollX,
    onExpanderClick,
    selectedTask,
    isAdmin,
  ])

  useEffect(() => {
    if (
      viewMode === dateSetup.viewMode &&
      ((viewDate && !currentViewDate) || (viewDate && currentViewDate?.valueOf() !== viewDate.valueOf()))
    ) {
      const dates = dateSetup.dates
      const index = dates.findIndex(
        (d, i) =>
          viewDate.valueOf() >= d.valueOf() && i + 1 !== dates.length && viewDate.valueOf() < dates[i + 1].valueOf(),
      )
      if (index === -1) {
        return
      }
      setCurrentViewDate(viewDate)
      setScrollX(columnWidth * index)
    }
  }, [viewDate, columnWidth, dateSetup.dates, dateSetup.viewMode, viewMode, currentViewDate, setCurrentViewDate])

  useEffect(() => {
    const { changedTask, action } = ganttEvent
    if (changedTask) {
      if (action === 'delete') {
        setGanttEvent({ action: '' })
        setBarTasks(barTasks.filter(t => t.id !== changedTask.id))
      } else if (action === 'move' || action === 'end' || action === 'start') {
        changedTask.y = barTasks.find(t => t.id === changedTask.id)?.y || 0
        const prevStateTask = barTasks.find(t => t.id === changedTask.id)
        if (
          prevStateTask &&
          (prevStateTask.start.getTime() !== changedTask.start.getTime() ||
            prevStateTask.end.getTime() !== changedTask.end.getTime())
        ) {
          // actions for change
          const newTaskList = barTasks.map(t => (t.id === changedTask.id ? changedTask : t))
          setBarTasks(newTaskList)
        }
      }
    }
  }, [ganttEvent, barTasks])

  useEffect(() => {
    if (failedTask) {
      setBarTasks(barTasks.map(t => (t.id !== failedTask.id ? t : failedTask)))
      setFailedTask(null)
    }
  }, [failedTask, barTasks])

  useEffect(() => {
    if (!listCellWidth) {
      setTaskListWidth(0)
    }
    if (taskListRef.current) {
      setTaskListWidth(taskListRef.current.offsetWidth)
    }
  }, [taskListRef, listCellWidth])

  useEffect(() => {
    if (wrapperRef.current) {
      setSvgContainerWidth(wrapperRef.current.offsetWidth - taskListWidth)
    }
  }, [wrapperRef, taskListWidth])

  useEffect(() => {
    if (ganttHeight) {
      setSvgContainerHeight(ganttHeight + headerHeight)
    } else {
      setSvgContainerHeight(tasks.length * rowHeight + headerHeight)
    }
  }, [ganttHeight, tasks, headerHeight, rowHeight, todayHeight])

  // scroll events
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (event.shiftKey || event.deltaX) {
        const scrollMove = event.deltaX ? event.deltaX : event.deltaY
        let newScrollX = scrollX + scrollMove
        if (newScrollX < 0) {
          newScrollX = 0
        } else if (newScrollX > svgWidth) {
          newScrollX = svgWidth
        }
        setScrollX(newScrollX)
        event.preventDefault()
      } else if (ganttHeight) {
        let newScrollY = scrollY + event.deltaY
        if (newScrollY < 0) {
          newScrollY = 0
        } else if (newScrollY > ganttFullHeight - ganttHeight) {
          newScrollY = ganttFullHeight - ganttHeight
        }
        if (newScrollY !== scrollY) {
          setScrollY(newScrollY)
          event.preventDefault()
        }
      }

      setIgnoreScrollEvent(true)
    }

    // subscribe if scroll is necessary
    wrapperRef.current?.addEventListener('wheel', handleWheel, {
      passive: false,
    })
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      wrapperRef.current?.removeEventListener('wheel', handleWheel)
    }
  }, [wrapperRef, scrollY, scrollX, ganttHeight, svgWidth, ganttFullHeight])

  const handleScrollY = (event: SyntheticEvent<HTMLDivElement>) => {
    if (scrollY !== event.currentTarget.scrollTop && !ignoreScrollEvent) {
      setScrollY(event.currentTarget.scrollTop)
      setIgnoreScrollEvent(true)
    } else {
      setIgnoreScrollEvent(false)
    }
  }

  const handleScrollX = (event: SyntheticEvent<HTMLDivElement>) => {
    if (scrollX !== event.currentTarget.scrollLeft && !ignoreScrollEvent) {
      setScrollX(event.currentTarget.scrollLeft)
      setIgnoreScrollEvent(true)
    } else {
      setIgnoreScrollEvent(false)
    }
  }

  /**
   * Handles arrow keys events and transform it to new scroll
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault()
    let newScrollY = scrollY
    let newScrollX = scrollX
    let isX = true
    switch (event.key) {
      case 'Down': // IE/Edge specific value
      case 'ArrowDown':
        newScrollY += rowHeight
        isX = false
        break
      case 'Up': // IE/Edge specific value
      case 'ArrowUp':
        newScrollY -= rowHeight
        isX = false
        break
      case 'Left':
      case 'ArrowLeft':
        newScrollX -= columnWidth
        break
      case 'Right': // IE/Edge specific value
      case 'ArrowRight':
        newScrollX += columnWidth
        break
    }
    if (isX) {
      if (newScrollX < 0) {
        newScrollX = 0
      } else if (newScrollX > svgWidth) {
        newScrollX = svgWidth
      }
      setScrollX(newScrollX)
    } else {
      if (newScrollY < 0) {
        newScrollY = 0
      } else if (newScrollY > ganttFullHeight - ganttHeight) {
        newScrollY = ganttFullHeight - ganttHeight
      }
      setScrollY(newScrollY)
    }
    setIgnoreScrollEvent(true)
  }

  /**
   * Task select event
   */
  const handleSelectedTask = (taskId: string) => {
    const newSelectedTask = barTasks.find(t => t.id === taskId)
    const oldSelectedTask = barTasks.find(t => !!selectedTask && t.id === selectedTask.id)
    if (onSelect) {
      if (oldSelectedTask) {
        onSelect(oldSelectedTask, false)
      }
      if (newSelectedTask) {
        onSelect(newSelectedTask, true)
      }
    }
    setSelectedTask(newSelectedTask)
  }

  const gridProps: GridProps = {
    svgWidth,
    ganttFullHeight,
    tasks: tasks,
    dates: dateSetup.dates,
    selectedTask,
  }
  const calendarProps: CalendarProps = {
    dateSetup,
    locale,
    headerHeight,
    columnWidth,
    fontFamily,
  }
  const barProps: TaskGanttContentProps = {
    tasks: barTasks,
    dates: dateSetup.dates,
    ganttEvent,
    selectedTask,
    rowHeight,
    columnWidth,
    arrowColor,
    timeStep,
    fontFamily,
    fontSize,
    arrowIndent,
    svgWidth,
    setGanttEvent,
    setFailedTask,
    setSelectedTask: handleSelectedTask,
    onDateChange,
    onProgressChange,
    onDoubleClick,
    onClick,
    onDelete,
    modalProps,
    updateBenefitModalProps,
  }

  return (
    <div>
      <div className={styles.wrapper} onKeyDown={handleKeyDown} tabIndex={0} ref={wrapperRef}>
        <TaskGantt
          gridProps={gridProps}
          calendarProps={calendarProps}
          barProps={barProps}
          ganttHeight={ganttHeight}
          ganttFullHeight={ganttFullHeight}
          scrollY={scrollY}
          scrollX={scrollX}
        />
        {ganttEvent.changedTask && (
          <Tooltip
            arrowIndent={arrowIndent}
            rowHeight={rowHeight}
            svgContainerHeight={svgContainerHeight}
            svgContainerWidth={svgContainerWidth}
            fontFamily={fontFamily}
            fontSize={fontSize}
            scrollX={scrollX}
            scrollY={scrollY}
            task={ganttEvent.changedTask}
            headerHeight={headerHeight}
            taskListWidth={taskListWidth}
            TooltipContent={TooltipContent}
            svgWidth={svgWidth}
          />
        )}
        <VerticalScroll
          ganttFullHeight={ganttFullHeight}
          ganttHeight={ganttHeight}
          headerHeight={headerHeight}
          scroll={scrollY}
          onScroll={handleScrollY}
        />
      </div>
      <HorizontalScroll svgWidth={svgWidth} taskListWidth={taskListWidth} scroll={scrollX} onScroll={handleScrollX} />
    </div>
  )
}
