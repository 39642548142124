import { WppTypography } from '@platform-ui-kit/components-library-react'

import { CircleSvg } from './CircleSvg'
import { DashedCircleSvg } from './DashedCircleSvg'
import styles from './Legend.module.scss'

const legendData = [
  {
    name: 'Milestone',
    svg: <CircleSvg color="var(--wpp-primary-color-400)" />,
  },
  {
    name: 'Benefit',
    svg: <CircleSvg color="var(--wpp-dataviz-color-cat-dark-9)" />,
  },
  {
    name: 'Convergent',
    svg: <CircleSvg color="var(--wpp-dataviz-color-cat-light-9)" />,
  },
  {
    name: 'Adoption',
    svg: <CircleSvg color="var(--wpp-dataviz-color-cat-light-1)" />,
  },
  {
    name: 'Migration',
    svg: <CircleSvg color="var(--wpp-dataviz-color-cat-light-8)" />,
  },
  {
    name: 'Features',
    svg: <DashedCircleSvg color="var(--wpp-dataviz-color-cat-neutral-4)" />,
  },
  {
    name: 'Products migrated',
    svg: <DashedCircleSvg color="var(--wpp-dataviz-color-cat-neutral-8)" />,
  },
]

export const Legend = () => {
  return (
    <div className={styles.legend}>
      {legendData.map(item => (
        <div className={styles.legendItem} key={item.name}>
          {item.svg}
          <WppTypography type="xs-body" tag="span" className={styles.legendItemText}>
            {item.name}
          </WppTypography>
        </div>
      ))}
    </div>
  )
}
