import { WppButton, WppDivider, WppModal, WppTypography } from '@platform-ui-kit/components-library-react'
import React, { useEffect, useState } from 'react'

import { areDatesValid, updateBenefitAPI } from './helpers/api-helpers'
import { Benefit } from './helpers/Benefit'
import { Item } from './helpers/Item'
import { ParityItem } from './helpers/ParityItem'
import styles from './modals.module.scss'
import { useEditBenefit } from '../../api/benefits/mutations/useEditBenefit'
import { queryClient } from '../../app/Root'
import { ApiQueryKeys } from '../../constants/apiQueryKeys'
import { useToast } from '../../hooks/useToast'
import { Task } from '../gantt/src'
import { Parity, TaskWithNoDates, TimeObject } from '../gantt/src/types/public-types'

interface UpdateBenefitProps {
  open: boolean
  setOpen: (open: boolean) => void
  benefit: Task
}

export const UpdateBenefitModal: React.FC<UpdateBenefitProps> = ({ open, setOpen, benefit }) => {
  const [form, setForm] = useState<TaskWithNoDates>(benefit)
  const { showToast } = useToast()
  const { mutateAsync: editBenefit } = useEditBenefit()

  useEffect(() => {
    setForm(benefit)
  }, [benefit])

  const handleUpdate = async () => {
    if (!benefit.id || !form.start || !form.end) {
      return
    }
    if (!areDatesValid(form as Task, showToast)) {
      return
    }
    const errorMessage = `Couldn't update ${benefit.name}. Check console for details.`
    const successMessage = `Benefit ${benefit.name} updated successfully.`
    await updateBenefitAPI({
      editBenefit,
      benefitId: benefit.id,
      newBenefit: form,
      errorMessage,
      successMessage,
      showToast,
    })
    await queryClient.invalidateQueries([ApiQueryKeys.ALL_BENEFITS])
    setOpen(false)
  }

  const handleChange = (value: TimeObject[], key: string) => {
    setForm(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  const handleParityChange = (value: Parity[]) => {
    setForm(prevState => ({
      ...prevState,
      parities: value,
    }))
  }

  return (
    <WppModal
      open={open}
      size="m"
      className={styles.modal}
      onWppModalClose={() => setOpen(false)}
      onWppModalOpen={() => setOpen(true)}
    >
      <h3 slot="header">Update Benefit</h3>
      <div slot="body">
        <div>
          <Benefit item={form} setForm={setForm} />
          <WppDivider className={styles.divider} />
          <WppTypography type="m-strong" tag="span">
            Convergence
          </WppTypography>
          <Item items={form.convergenMilestone} name="convergenMilestone" handleChange={handleChange} />
          <WppDivider className={styles.divider} />
          <WppTypography type="m-strong" tag="span">
            Migration
          </WppTypography>
          <Item items={form.migrationMilestone} name="migrationMilestone" handleChange={handleChange} />
          <WppDivider className={styles.divider} />
          <WppTypography type="m-strong" tag="span">
            Adoption
          </WppTypography>
          <Item items={form.adoptionMilestone} name="adoptionMilestone" handleChange={handleChange} />
          <WppDivider className={styles.divider} />
          <WppTypography type="m-strong" tag="span">
            Milestones
          </WppTypography>
          <ParityItem items={form.parities} handleChange={handleParityChange} />
        </div>
      </div>
      <div slot="actions" className={styles.actions}>
        <WppButton variant="secondary" size="s" onClick={() => setOpen(false)}>
          Cancel
        </WppButton>
        <WppButton variant="primary" size="s" onClick={handleUpdate}>
          Save
        </WppButton>
      </div>
    </WppModal>
  )
}
