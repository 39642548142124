// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sPcXy {\n  overflow: hidden auto;\n  width: 1rem;\n  flex-shrink: 0;\n  /*firefox*/\n  scrollbar-width: thin;\n}\n.sPcXy::-webkit-scrollbar {\n  width: 1.1rem;\n  height: 1.1rem;\n}\n.sPcXy::-webkit-scrollbar-corner {\n  background: transparent;\n}\n.sPcXy::-webkit-scrollbar-thumb {\n  border: 6px solid transparent;\n  background: rgba(0, 0, 0, 0.2);\n  background: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2));\n  border-radius: 10px;\n  background-clip: padding-box;\n}\n.sPcXy::-webkit-scrollbar-thumb:hover {\n  border: 4px solid transparent;\n  background: rgba(0, 0, 0, 0.3);\n  background: var(--palette-black-alpha-30, rgba(0, 0, 0, 0.3));\n  background-clip: padding-box;\n}\n", "",{"version":3,"sources":["webpack://./src/components/gantt/src/components/other/vertical-scroll.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,cAAc;EACd,UAAU;EACV,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,cAAc;AAChB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,6BAA6B;EAC7B,8BAA8B;EAC9B,6DAA6D;EAC7D,mBAAmB;EACnB,4BAA4B;AAC9B;AACA;EACE,6BAA6B;EAC7B,8BAA8B;EAC9B,6DAA6D;EAC7D,4BAA4B;AAC9B","sourcesContent":[".scroll {\n  overflow: hidden auto;\n  width: 1rem;\n  flex-shrink: 0;\n  /*firefox*/\n  scrollbar-width: thin;\n}\n.scroll::-webkit-scrollbar {\n  width: 1.1rem;\n  height: 1.1rem;\n}\n.scroll::-webkit-scrollbar-corner {\n  background: transparent;\n}\n.scroll::-webkit-scrollbar-thumb {\n  border: 6px solid transparent;\n  background: rgba(0, 0, 0, 0.2);\n  background: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2));\n  border-radius: 10px;\n  background-clip: padding-box;\n}\n.scroll::-webkit-scrollbar-thumb:hover {\n  border: 4px solid transparent;\n  background: rgba(0, 0, 0, 0.3);\n  background: var(--palette-black-alpha-30, rgba(0, 0, 0, 0.3));\n  background-clip: padding-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroll": "sPcXy"
};
export default ___CSS_LOADER_EXPORT___;
