import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Bar } from './bar/bar'
import { AddItemModalProps } from './bar/bar-display'
import style from './task-list.module.css'
import { useAdminContext } from '../../../../../app/AdminContext'
import { BarTask } from '../../types/bar-task'
import { GanttContentMoveAction } from '../../types/gantt-task-actions'
import { Task } from '../../types/public-types'

export interface TaskItemProps {
  task: BarTask
  isProgressChangeable: boolean
  isDateChangeable: boolean
  isDelete: boolean
  isSelected: boolean
  isFocus?: boolean
  onEventStart: (
    action: GanttContentMoveAction,
    selectedTask: BarTask,
    event?: React.MouseEvent | React.KeyboardEvent,
  ) => any
  modalProps: AddItemModalProps
  updateBenefitModalProps: UpdateBenefitModalProps
}

export interface UpdateBenefitModalProps {
  setModalBenefit: (benefit: Task) => void
  setIsModalOpened: (isModalOpened: boolean) => void
}

export const TaskItem: React.FC<TaskItemProps> = props => {
  const isAdmin = useAdminContext()
  const { task, isDelete, onEventStart, isSelected, isFocus } = {
    ...props,
  }
  const isSelectedOffset = isSelected ? (task.features.length + (isAdmin ? 1 : 0)) * 32 : 0

  const handleOpenModal = () => {
    onEventStart('select', task)
    if (isSelected && isAdmin) {
      props.updateBenefitModalProps.setModalBenefit(task)
      props.updateBenefitModalProps.setIsModalOpened(true)
    }
  }

  return (
    <g
      onKeyDown={e => {
        switch (e.key) {
          case 'Delete': {
            if (isDelete) onEventStart('delete', task, e)
            break
          }
        }
        e.stopPropagation()
      }}
      onDoubleClick={e => {
        onEventStart('dblclick', task, e)
      }}
      onClick={e => {
        onEventStart('click', task, e)
      }}
      onFocus={() => {
        onEventStart('select', task)
      }}
      className={isFocus === false ? style.taskItem : undefined}
    >
      <Bar {...props} />
      <foreignObject x={task.x1 - 121} y={task.y + 8 + isSelectedOffset} width={115} height={70} className={style.text}>
        <div className={style.textAlign} onClick={handleOpenModal}>
          <WppTypography type="s-strong" tag="span" className={style.barLabel}>
            {task.name}
          </WppTypography>
        </div>
      </foreignObject>
    </g>
  )
}
