import { createUseQuery } from 'api/common/createUseQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

import { fetchAllBenefits } from '../fetchers/fetchAllBenefits'

export const useFetchAllBenefits = createUseQuery({
  queryKey: ApiQueryKeys.ALL_BENEFITS,
  fetcher: fetchAllBenefits,
  selector: res => res?.data.data ?? [],
})
