// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kKzM4{outline:none;cursor:pointer}.WIKs0{-webkit-user-select:none;user-select:none}.kKzM4:hover{opacity:.5}", "",{"version":3,"sources":["webpack://./src/components/gantt/src/components/task-item/milestone/milestone.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,cAAA,CAGF,OACE,wBAAA,CAAA,gBAAA,CAGF,aACE,UAAA","sourcesContent":[".milestoneWrapper {\n  outline: none;\n  cursor: pointer;\n}\n\n.milestoneBackground {\n  user-select: none;\n}\n\n.milestoneWrapper:hover {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"milestoneWrapper": "kKzM4",
	"milestoneBackground": "WIKs0"
};
export default ___CSS_LOADER_EXPORT___;
