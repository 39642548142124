import { ToastState } from '@platform-ui-kit/components-library/components'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { BaseDateInfo } from '../../../api/benefits/fetchers/createBenefit'
import {
  EditBenefitParams,
  FeatureOrProduct,
  ObjectType,
  TimeObject as TimeObjectAPI,
  TimeObjectType,
} from '../../../api/benefits/fetchers/editBenefit'
import { BenefitDto } from '../../../types/dto/BenefitDto'
import { Parity, Task, TaskWithNoDates, TimeObject } from '../../gantt/src/types/public-types'

export interface Params {
  editBenefit: UseMutateAsyncFunction<
    AxiosResponse<BenefitDto, any>,
    AxiosError<unknown, any>,
    EditBenefitParams,
    unknown
  >
  benefitId: string
  newBenefit?: TaskWithNoDates
  newFeatureOrProduct?: FeatureOrProduct
  newFeatureOrProductId?: string
  errorMessage: string
  successMessage: string
  showToast: (config: ToastState) => void
}

export const updateBenefitAPI = async ({
  editBenefit,
  benefitId,
  newBenefit,
  newFeatureOrProduct,
  newFeatureOrProductId,
  errorMessage,
  successMessage,
  showToast,
}: Params) => {
  try {
    // merge features and products lists
    const features =
      newBenefit?.features
        .map(feature => ({ ...feature, objectType: ObjectType.feature }))
        .filter(feature => feature.id !== newFeatureOrProductId)
        .map(feature => feature as FeatureOrProduct) ?? []
    const products =
      newBenefit?.products
        .map(product => ({ ...product, objectType: ObjectType.product }))
        .filter(product => product.id !== newFeatureOrProductId)
        .map(product => product as FeatureOrProduct) ?? []

    let featureOrProductArray = features.concat(products)
    if (newFeatureOrProduct) {
      featureOrProductArray.push(newFeatureOrProduct)
    }

    // merge milestones
    const convergence =
      newBenefit?.convergenMilestone.map(
        i => ({ ...i, timeObjectType: TimeObjectType.convergence } as TimeObjectAPI),
      ) ?? []
    const migration =
      newBenefit?.migrationMilestone.map(i => ({ ...i, timeObjectType: TimeObjectType.migration } as TimeObjectAPI)) ??
      []
    const adoption =
      newBenefit?.adoptionMilestone.map(i => ({ ...i, timeObjectType: TimeObjectType.adoption } as TimeObjectAPI)) ?? []

    let milestonesArray = convergence.concat(migration).concat(adoption)

    const milestones = newBenefit?.parities.map(i => i as BaseDateInfo) ?? []

    await editBenefit({
      id: benefitId,
      start: newBenefit?.start,
      end: newBenefit?.end,
      name: newBenefit?.name,
      featureOrProducts: featureOrProductArray,
      timeObjects: milestonesArray,
      milestone: milestones,
    })
    showToast({
      type: 'success',
      message: successMessage,
    })
  } catch (e) {
    showToast({
      type: 'error',
      message: errorMessage,
    })
    console.error(e)
  }
}

export const areDatesValid = (task: Task, showToast: (config: ToastState) => void): boolean => {
  const convergence = _areDatesValid(task.convergenMilestone, 'Convergence milestone', task.start, task.end, showToast)
  const adoption = _areDatesValid(task.adoptionMilestone, 'Adoption milestone', task.start, task.end, showToast)
  const migration = _areDatesValid(task.migrationMilestone, 'Migration milestone', task.start, task.end, showToast)
  const parities = _areDatesValidParity(task.parities, task.start, task.end, showToast)
  return convergence && adoption && migration && parities
}

const _areDatesValid = (
  objects: TimeObject[],
  name: string,
  benefitStart: Date,
  benefitEnd: Date,
  showToast: (config: ToastState) => void,
): boolean => {
  for (let o of objects) {
    if (!o.end || !o.start) {
      showToast({
        type: 'error',
        message: `Please fill all the fields for ${name}.`,
      })
      return false
    }
    if (o.start < benefitStart || o.end > benefitEnd || o.start > benefitEnd || o.end < benefitStart) {
      showToast({
        type: 'error',
        message: `The ${name} dates must be between the benefit start and end dates.`,
      })
      return false
    }
    if (o.start > o.end) {
      showToast({
        type: 'error',
        message: `The ${name} start date can not be greater than end date.`,
      })
      return false
    }
  }
  return true
}

const _areDatesValidParity = (
  objects: Parity[],
  benefitStart: Date,
  benefitEnd: Date,
  showToast: (config: ToastState) => void,
): boolean => {
  for (let o of objects) {
    if (o.date < benefitStart || o.date > benefitEnd) {
      showToast({
        type: 'error',
        message: 'The milestone dates must be between the benefit start and end dates.',
      })
      return false
    }
  }
  return true
}
