import { WppTypography } from '@platform-ui-kit/components-library-react'
import React, { ReactChild } from 'react'

import styles from './grid.module.css'
import { useAdminContext } from '../../../../../app/AdminContext'
import { getRowHeight, taskXCoordinate } from '../../helpers/bar-helper'
import { addToDate } from '../../helpers/date-helper'
import { BarTask } from '../../types/bar-task'
import { Task } from '../../types/public-types'
import { defaultTheme } from '../gantt/gantt'

export interface GridBodyProps {
  tasks: Task[]
  dates: Date[]
  svgWidth: number
  ganttFullHeight: number
  selectedTask?: BarTask
}
export const GridBody: React.FC<GridBodyProps> = ({ tasks, dates, svgWidth, ganttFullHeight, selectedTask }) => {
  const isAdmin = useAdminContext()
  let y = 0
  const gridRows: ReactChild[] = []
  const rowLines: ReactChild[] = [
    <line key="RowLineFirst" x="0" y1={0} x2={svgWidth} y2={0} className={styles.gridRowLine} />,
  ]
  for (const task of tasks) {
    const rowHeight = getRowHeight(task, selectedTask, isAdmin)
    gridRows.push(
      <rect key={'Row' + task.id} x="0" y={y} width={svgWidth} height={rowHeight} className={styles.gridRow} />,
    )
    rowLines.push(
      <line
        key={'RowLine' + task.id}
        x="0"
        y1={y + rowHeight}
        x2={svgWidth}
        y2={y + rowHeight}
        className={styles.gridRowLine}
      />,
    )
    y += getRowHeight(task, selectedTask, isAdmin)
  }

  const now = new Date()
  let tickX = 0
  const ticks: ReactChild[] = []
  let today: ReactChild = <rect />
  for (let i = 0; i < dates.length; i++) {
    const date = dates[i]
    ticks.push(<line key={date.getTime()} x1={tickX} y1={0} x2={tickX} y2={y} className={styles.gridTick} />)
    if (
      (i + 1 !== dates.length && date.getTime() < now.getTime() && dates[i + 1].getTime() >= now.getTime()) ||
      // if current date is last
      (i !== 0 &&
        i + 1 === dates.length &&
        date.getTime() < now.getTime() &&
        addToDate(date, date.getTime() - dates[i - 1].getTime(), 'millisecond').getTime() >= now.getTime())
    ) {
      const x = taskXCoordinate(now, dates, defaultTheme.columnWidth)
      today = (
        <>
          <line
            x1={x}
            x2={x}
            y1={0}
            y2={ganttFullHeight - 40}
            stroke="var(--wpp-dataviz-color-cat-neutral-3)"
            strokeDasharray="2 2"
          />
          <rect
            x={x - 43}
            y={ganttFullHeight - 40}
            width={86}
            height={36}
            fill="var(--wpp-dataviz-color-cat-neutral-3)"
            rx={8}
            ry={8}
          />
          <foreignObject x={x - 43} y={ganttFullHeight - 40} width={86} height={36}>
            <div className={styles.text}>
              <WppTypography type="xs-strong" tag="span" className={styles.itemLabel}>
                We are here
              </WppTypography>
            </div>
          </foreignObject>
        </>
      )
    }
    tickX += defaultTheme.columnWidth
  }
  return (
    <g className="gridBody">
      <g className="rows">{gridRows}</g>
      <g className="rowLines">{rowLines}</g>
      <g className="ticks">{ticks}</g>
      <g className="today">{today}</g>
    </g>
  )
}
