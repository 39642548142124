import { benefitsRoadmapApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'
import { BenefitDto } from 'types/dto/BenefitDto'

import { BaseDateInfo, BaseInfo } from './createBenefit'

export enum TimeObjectType {
  convergence = 'convergence',
  adoption = 'adoption',
  migration = 'migration',
}

export enum ObjectType {
  feature = 'feature',
  product = 'product',
}

interface Check {
  check: boolean
  name: string
}

export interface TimeObject extends BaseInfo {
  timeObjectType: TimeObjectType
}

export interface FeatureOrProduct extends BaseInfo {
  objectType: ObjectType
  description: string
  checks: Check[]
}

export interface EditBenefitParams {
  id: string
  name?: string
  start?: Date
  end?: Date
  timeObjects?: TimeObject[]
  featureOrProducts?: FeatureOrProduct[]
  milestone?: BaseDateInfo[]
}

export const editBenefit = ({
  id,
  name,
  end,
  start,
  featureOrProducts,
  timeObjects,
  milestone,
}: EditBenefitParams): CancelableRequestProducer<BenefitDto> =>
  benefitsRoadmapApi.patch(`/benefit/${id}`, { name, end, start, featureOrProducts, timeObjects, milestone })
