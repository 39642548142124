import styles from './calendar.module.scss'
import { TopPartOfCalendar } from './top-part-of-calendar'
import { getLocaleMonth } from '../../helpers/date-helper'
import { DateSetup } from '../../types/date-setup'

export interface CalendarProps {
  dateSetup: DateSetup
  locale: string
  headerHeight: number
  columnWidth: number
  fontFamily: string
}

export const Calendar: React.FC<CalendarProps> = ({ dateSetup, locale, columnWidth, fontFamily }) => {
  const topHeight = 44
  const bottomHeight = 48
  const topFontSize = 20
  const bottomFontSize = 16

  const getCalendarValuesForMonth = () => {
    const topValues = []
    const bottomValues = []
    for (let i = 0; i < dateSetup.dates.length; i++) {
      const date = dateSetup.dates[i]
      const bottomValue = getLocaleMonth(date, locale)
      bottomValues.push(
        <text
          key={bottomValue + date.getFullYear()}
          y={topHeight + bottomHeight * 0.5 + bottomFontSize / 2}
          x={columnWidth * i + columnWidth * 0.5}
          className={styles.calendarBottomText}
          fontSize={bottomFontSize}
        >
          {bottomValue}
        </text>,
      )
      if (date.getMonth() % 3 === 0) {
        let topValue = `Q${Math.floor((date.getMonth() + 3) / 3)} ${date.getFullYear()}`
        let xText = (i + 1) * columnWidth + columnWidth * 0.5
        let y2Line = topHeight

        if (dateSetup.dates.length - i < 3) {
          topValue = ''
          y2Line = 0
        }

        if (i === 0) {
          y2Line = 0
        }

        topValues.push(
          <TopPartOfCalendar
            key={topValue}
            value={topValue}
            x1Line={columnWidth * i}
            y1Line={0}
            y2Line={y2Line}
            xText={xText}
            yText={topHeight * 0.5 + 6}
            fontSize={topFontSize}
          />,
        )
      }
    }
    return [topValues, bottomValues]
  }

  let [topValues, bottomValues] = getCalendarValuesForMonth()

  return (
    <g className="calendar" fontFamily={fontFamily}>
      <rect
        x={0}
        y={0}
        width={columnWidth * dateSetup.dates.length}
        height={topHeight}
        className={styles.calendarHeader}
      />
      <rect
        x={0}
        y={topHeight}
        width={columnWidth * dateSetup.dates.length}
        height={bottomHeight}
        className={styles.bottomHeader}
        rx={8}
      />
      {bottomValues} {topValues}
    </g>
  )
}
