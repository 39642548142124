import { benefitsRoadmapApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'
import { BenefitDto } from 'types/dto/BenefitDto'

export interface BaseInfo {
  name: string
  start: Date
  end: Date
}

export interface BaseDateInfo {
  name: string
  date: Date
}

export interface PromptParams {
  benefit: BaseInfo
  convergence: BaseInfo[]
  adoption: BaseInfo[]
  migration: BaseInfo[]
  milestone: BaseDateInfo[]
}

export const createBenefit = ({
  benefit,
  convergence,
  adoption,
  migration,
  milestone,
}: PromptParams): CancelableRequestProducer<BenefitDto> =>
  benefitsRoadmapApi.post('/benefit', { benefit, convergence, adoption, migration, milestone })
