interface CircleSvgProps {
  color: string
  width?: number
  height?: number
}

export const CircleSvg: React.FC<CircleSvgProps> = ({ color, width = 10, height = 10 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} rx="5" fill={color} />
    </svg>
  )
}
