import { WppDatepicker, WppIconAddCircle, WppIconTrash, WppInput } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'

import { Parity } from 'components/gantt/src/types/public-types'

import { useToast } from '../../../hooks/useToast'
import styles from '../modals.module.scss'

interface ItemProps {
  items: Parity[]
  handleChange: (value: Parity[]) => void
}

export interface NewParityItem {
  name: string
  date?: Date
}

export const ParityItem: React.FC<ItemProps> = ({ items, handleChange }) => {
  const [newItem, setNewItem] = useState<NewParityItem>({ name: '' })
  const { showToast } = useToast()

  const handleAdd = () => {
    if (!newItem.name || !newItem.date) {
      showToast({ message: 'Please fill all fields', type: 'error' })
      return
    }
    handleChange(items.concat(newItem as Parity))
    setNewItem({ name: '' })
  }

  const handleDelete = (index: number) => {
    handleChange(items.filter((a, i) => i !== index))
  }

  const handleChan = (index: number, newValue: any, key: string, itemFor?: Parity) => {
    if (itemFor)
      handleChange(
        items.map((a, i) =>
          i !== index
            ? a
            : {
                ...a,
                [key]: newValue,
              },
        ),
      )
    else {
      setNewItem(prevState => ({
        ...prevState,
        [key]: newValue,
      }))
    }
  }

  const itemElement = (index: number, itemFor?: Parity) => {
    const item = index === -1 ? newItem : itemFor
    return (
      <div className={styles.checklistItem}>
        <WppInput
          name={item?.name}
          placeholder="Milestone name here"
          value={item?.name}
          onWppChange={e => handleChan(index, e.target.value, 'name', itemFor)}
          required
        />
        <WppDatepicker
          key={item?.date?.toDateString()} // TODO remove when state change triggers rerender (duplicated key warning will be gone)
          className={styles.datepicker}
          value={item?.date?.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) ?? ''}
          onWppChange={e => handleChan(index, e.detail.date as Date, 'date', itemFor)}
          required
        />
        {index !== -1 ? (
          <div className={styles.delete}>
            <WppIconTrash height={20} width={20} onClick={() => handleDelete(index)} className={styles.deleteIcon} />
          </div>
        ) : (
          <div className={styles.addIcon}>
            <WppIconAddCircle height={20} width={20} onClick={handleAdd} />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={styles.item}>
      {items.map((itemFor, index) => (
        <div key={index}>{itemElement(index, itemFor)}</div>
      ))}
      {itemElement(-1)}
    </div>
  )
}
