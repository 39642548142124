// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nF11J{min-height:calc(100vh - var(--wpp-os-header-height));background-color:var(--wpp-grey-color-100)}._BCo4{width:1016px;margin:0 auto}", "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAAA,OACE,oDAAA,CACA,0CAAA,CAGF,OACE,YAAA,CACA,aAAA","sourcesContent":[".container {\n  min-height: calc(100vh - var(--wpp-os-header-height));\n  background-color: var(--wpp-grey-color-100);\n}\n\n.pageContent {\n  width: 1016px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "nF11J",
	"pageContent": "_BCo4"
};
export default ___CSS_LOADER_EXPORT___;
