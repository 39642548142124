import { WppButton, WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import React, { useEffect, useState } from 'react'

import styles from 'pages/gantt/GanttPage.module.scss'

import { useFetchAllBenefits } from '../../api/benefits/queries/useFetchAllBenefits'
import { useAdminContext } from '../../app/AdminContext'
import { Gantt, Task, ViewMode } from '../../components/gantt/src'
import { TimeObject } from '../../components/gantt/src/types/public-types'
import { Legend } from '../../components/legend/Legend'
import { AddBenefitModal } from '../../components/modals/AddBenefitModal'
import { AddItemModal } from '../../components/modals/AddItemModal'
import { SaveChangesModal } from '../../components/modals/SaveChangesModal'
import { UpdateBenefitModal } from '../../components/modals/UpdateBenefitModal'
import { ViewItemModal } from '../../components/modals/ViewItemModal'

export function GanttPage() {
  const { data: benefits } = useFetchAllBenefits({
    params: { itemsPerPage: 1000 },
  })

  const [tasks, setTasks] = useState<Task[]>([])
  const [oldTasks, setOldTasks] = useState<Task[]>([])
  const [changedTasks, setChangedTasks] = useState<Task[]>([])

  useEffect(() => {
    const t = benefits
      .map(
        b =>
          ({
            id: b.id,
            name: b.name,
            start: new Date(b.start),
            end: new Date(b.end),
            convergenMilestone: b.convergence.map(c => ({
              id: c.id,
              name: c.name,
              start: new Date(c.start),
              end: new Date(c.end),
            })),
            adoptionMilestone: b.adoption.map(a => ({
              id: a.id,
              name: a.name,
              start: new Date(a.start),
              end: new Date(a.end),
            })),
            migrationMilestone: b.migration.map(m => ({
              id: m.id,
              name: m.name,
              start: new Date(m.start),
              end: new Date(m.end),
            })),
            features: b.feature.map(f => ({
              id: f.id,
              name: f.name,
              description: f.description,
              checks: f.checks,
              start: new Date(f.start),
              end: new Date(f.end),
            })),
            products: b.product.map(p => ({
              id: p.id,
              name: p.name,
              description: p.description,
              checks: p.checks,
              start: new Date(p.start),
              end: new Date(p.end),
            })),
            parities: b.parities.map(p => ({
              id: p.id,
              name: p.name,
              date: new Date(p.date),
            })),
          } as unknown as Task),
      )
      .sort((a, b) => a.start.getTime() - b.start.getTime())

    setTasks(t)
    setOldTasks(structuredClone(t))
  }, [benefits])

  const [addBenefit, setAddBenefit] = useState(false)
  const [saveBenefit, setSaveBenefit] = useState(false)
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isUpdateModalOpened, setIsUpdateModalOpened] = useState(false)
  const [modalItem, setModalItem] = useState<TimeObject | undefined>(undefined)
  const [modalItemName, setModalItemName] = useState('')
  const [modalBenefit, setModalBenefit] = useState<Task | undefined>(undefined)

  const isAdmin = useAdminContext()
  console.log('isAdmin:', isAdmin)

  const handleTaskChange = (task: Task) => {
    console.log('On date change Id:' + task.id)
    let newTasks = tasks.map(t => (t.id === task.id ? task : t))
    setTasks(newTasks)

    if (changedTasks.some(t => t.id === task.id)) {
      let newChangedTasks = changedTasks.map(t => (t.id === task.id ? task : t))
      setChangedTasks(newChangedTasks)
    } else {
      setChangedTasks([...changedTasks, task])
    }
  }

  const handleDiscardChangedBenefit = (id?: string) => {
    // remove benefit with ID from changedTasks
    let changed = changedTasks.filter(t => t.id !== id)
    setChangedTasks(changed)

    // restore old benefit value from oldTasks
    let newTasks = tasks.map(t => (t.id === id ? oldTasks.find(o => o.id === id) ?? t : t))
    setTasks(newTasks)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <WppTypography type="3xl-heading" tag="h1" className={styles.title}>
          Benefits Roadmap 2023-24
        </WppTypography>
        {isAdmin && (
          <div className={styles.buttonGroup}>
            {changedTasks.length > 0 && (
              <WppButton className={styles.button} size="m" variant="secondary" onClick={() => setSaveBenefit(true)}>
                Save Changes
              </WppButton>
            )}
            <WppButton className={styles.button} size="m" onClick={() => setAddBenefit(true)}>
              Add Benefit
            </WppButton>
          </div>
        )}
      </div>
      <WppCard className={styles.card}>
        {tasks.length > 0 ? (
          <Gantt
            tasks={tasks}
            onDateChange={handleTaskChange}
            viewMode={ViewMode.Month}
            modalProps={{
              setIsModalOpened,
              setModalItem,
              setModalItemName,
              setModalBenefit,
            }}
            updateBenefitModalProps={{
              setIsModalOpened: setIsUpdateModalOpened,
              setModalBenefit,
            }}
          />
        ) : (
          <div className={styles.emptyGant}>
            <WppTypography type="2xl-heading" tag="h2">
              No benefits found
            </WppTypography>
          </div>
        )}
        <Legend />
      </WppCard>
      {isAdmin ? (
        <>
          {modalBenefit && (
            <>
              <AddItemModal
                open={isModalOpened}
                setOpen={setIsModalOpened}
                itemName={modalItemName}
                benefit={modalBenefit}
                item={modalItem}
              />
              <UpdateBenefitModal open={isUpdateModalOpened} setOpen={setIsUpdateModalOpened} benefit={modalBenefit} />
            </>
          )}
          <AddBenefitModal open={addBenefit} setOpen={setAddBenefit} />
          <SaveChangesModal
            open={saveBenefit}
            setOpen={setSaveBenefit}
            changedBenefits={changedTasks}
            oldBenefits={oldTasks}
            handleDiscardChangedBenefit={handleDiscardChangedBenefit}
          />
        </>
      ) : (
        <>
          {modalItem && (
            <ViewItemModal
              open={isModalOpened}
              setOpen={setIsModalOpened}
              itemName={modalItemName}
              benefitName={modalBenefit?.name ?? ''}
              item={modalItem}
            />
          )}
        </>
      )}
    </div>
  )
}

export default GanttPage
