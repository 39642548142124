// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wv084 {\n  overflow: hidden;\n  color: var(--wpp-grey-color-000);\n}\n\n.SlFpW {\n  z-index: 10;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 100%;\n  text-align: center;\n}\n\n.hzJRA {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n          user-select: none;\n}\n\n.GIVp5 {\n  opacity: 0.5;\n}\n", "",{"version":3,"sources":["webpack://./src/components/gantt/src/components/task-item/task-list.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".barLabel {\n  overflow: hidden;\n  color: var(--wpp-grey-color-000);\n}\n\n.textAlign {\n  z-index: 10;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 100%;\n  text-align: center;\n}\n\n.text {\n  -webkit-touch-callout: none;\n  user-select: none;\n}\n\n.taskItem {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"barLabel": "Wv084",
	"textAlign": "SlFpW",
	"text": "hzJRA",
	"taskItem": "GIVp5"
};
export default ___CSS_LOADER_EXPORT___;
