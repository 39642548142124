// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZqXxh{display:flex;gap:12px;padding-top:12px}.E2xMb{display:flex;gap:6px;align-items:center}", "",{"version":3,"sources":["webpack://./src/components/legend/Legend.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,QAAA,CACA,gBAAA,CAGF,OACE,YAAA,CACA,OAAA,CACA,kBAAA","sourcesContent":[".legend {\n  display: flex;\n  gap: 12px;\n  padding-top: 12px;\n}\n\n.legendItem {\n  display: flex;\n  gap: 6px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legend": "ZqXxh",
	"legendItem": "E2xMb"
};
export default ___CSS_LOADER_EXPORT___;
