import { useOs } from '@wpp-open/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import styles from 'app/App.module.scss'

import { AdminProvider } from './AdminContext'
import GanttPage from '../pages/gantt/GanttPage'

export function App() {
  /**
   * This hook is provided by the utility package for more convenient work with OS context
   * @return osContext
   * - is a subscribe function that returns you the main context object with MicroAppContext data
   * @return osApi
   * - methods to fire actions on the parent OS level
   *
   * Read more about it in the README file
   */
  const { osContext } = useOs()

  return (
    <AdminProvider>
      <BrowserRouter basename={osContext.baseUrl}>
        <div className={styles.container}>
          <Routes>
            <Route path="/" element={<GanttPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </AdminProvider>
  )
}
