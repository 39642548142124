import { useOs } from '@wpp-open/react'
import { createContext, PropsWithChildren, useContext, useMemo } from 'react'

const AdminContext = createContext(false)

export const AdminProvider = ({ children }: PropsWithChildren<{}>) => {
  const { osContext } = useOs()
  const hasAdminPermission = useMemo(
    () =>
      osContext.permissions.reduce(
        (acc, curr) =>
          acc ||
          (curr.account_id === osContext.navigationTree.rootId &&
            curr.permissions.reduce((a, c) => a || c === 'WPP_BENEFITS_ROADMAP_MODIFY', false)),
        false,
      ),
    [osContext],
  )

  return <AdminContext.Provider value={hasAdminPermission}>{children}</AdminContext.Provider>
}

export const useAdminContext = () => useContext(AdminContext)
