import { WppButton, WppDivider, WppModal, WppTypography } from '@platform-ui-kit/components-library-react'
import React, { useState } from 'react'

import { areDatesValid } from './helpers/api-helpers'
import { Benefit } from './helpers/Benefit'
import { Item } from './helpers/Item'
import { ParityItem } from './helpers/ParityItem'
import styles from './modals.module.scss'
import { BaseDateInfo, BaseInfo } from '../../api/benefits/fetchers/createBenefit'
import { useCreateBenefit } from '../../api/benefits/mutations/useCreateBenefit'
import { queryClient } from '../../app/Root'
import { ApiQueryKeys } from '../../constants/apiQueryKeys'
import { useToast } from '../../hooks/useToast'
import { Parity, Task, TaskWithNoDates, TimeObject } from '../gantt/src/types/public-types'

interface AddBenefitProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export const emptyTask = {
  name: '',
  convergenMilestone: [],
  adoptionMilestone: [],
  migrationMilestone: [],
  parities: [],
  features: [],
  products: [],
}

export const AddBenefitModal: React.FC<AddBenefitProps> = ({ open, setOpen }) => {
  const [newTask, setNewTask] = useState<TaskWithNoDates>(emptyTask)

  const { showToast } = useToast()
  const { mutateAsync: createBenefit } = useCreateBenefit()

  const handleSave = async () => {
    if (
      !newTask.name ||
      !newTask.start ||
      !newTask.end ||
      !newTask.convergenMilestone ||
      !newTask.adoptionMilestone ||
      !newTask.migrationMilestone ||
      newTask.convergenMilestone.length === 0 ||
      newTask.adoptionMilestone.length === 0 ||
      newTask.migrationMilestone.length === 0
    ) {
      showToast({
        type: 'error',
        message: 'Please fill all the fields.',
      })
      return
    }
    if (!areDatesValid(newTask as Task, showToast)) {
      return
    }
    try {
      await createBenefit({
        benefit: {
          name: newTask.name,
          start: newTask.start,
          end: newTask.end,
        },
        convergence: newTask.convergenMilestone.map(milestone => milestone as BaseInfo),
        adoption: newTask.adoptionMilestone.map(milestone => milestone as BaseInfo),
        migration: newTask.migrationMilestone.map(milestone => milestone as BaseInfo),
        milestone: newTask.parities.map(milestone => milestone as BaseDateInfo),
      })
      await queryClient.invalidateQueries([ApiQueryKeys.ALL_BENEFITS])
      showToast({
        type: 'success',
        message: 'Benefit created successfully.',
      })
      setOpen(false)
      setNewTask(emptyTask)
    } catch (e) {
      let message = "Couldn't create benefit. Check console for details."
      showToast({
        type: 'error',
        message,
      })
      console.error(e)
    }
  }

  const handleChange = (value: TimeObject[], key: string) => {
    setNewTask(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  const handleParityChange = (value: Parity[]) => {
    setNewTask(prevState => ({
      ...prevState,
      parities: value,
    }))
  }

  return (
    <WppModal
      open={open}
      size="m"
      className={styles.modal}
      onWppModalClose={() => setOpen(false)}
      onWppModalOpen={() => setOpen(true)}
    >
      <h3 slot="header">Add Benefit</h3>
      <div slot="body">
        <div>
          <Benefit item={newTask} setForm={setNewTask} />
          <WppDivider className={styles.divider} />
          <WppTypography type="m-strong" tag="span">
            Convergence
          </WppTypography>
          <Item items={newTask.convergenMilestone} name="convergenMilestone" handleChange={handleChange} />
          <WppDivider className={styles.divider} />
          <WppTypography type="m-strong" tag="span">
            Migration
          </WppTypography>
          <Item items={newTask.migrationMilestone} name="migrationMilestone" handleChange={handleChange} />
          <WppDivider className={styles.divider} />
          <WppTypography type="m-strong" tag="span">
            Adoption
          </WppTypography>
          <Item items={newTask.adoptionMilestone} name="adoptionMilestone" handleChange={handleChange} />
          <WppDivider className={styles.divider} />
          <WppTypography type="m-strong" tag="span">
            Milestones
          </WppTypography>
          <ParityItem items={newTask.parities} handleChange={handleParityChange} />
        </div>
      </div>
      <div slot="actions" className={styles.actions}>
        <WppButton variant="secondary" size="s" onClick={() => setOpen(false)}>
          Cancel
        </WppButton>
        <WppButton variant="primary" size="s" onClick={handleSave}>
          Save
        </WppButton>
      </div>
    </WppModal>
  )
}
