interface CircleSvgProps {
  color: string
  width?: number
  height?: number
}

export const DashedCircleSvg: React.FC<CircleSvgProps> = ({ color, width = 10, height = 10 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width={width - 1}
        height={height - 1}
        rx="5"
        fillOpacity="0"
        stroke={color}
        strokeDasharray="2 2"
      />
    </svg>
  )
}
