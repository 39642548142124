// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b9R48 {\n  fill: #fff;\n}\n\n.b9R48:nth-child(even) {\n  fill: #f5f5f5;\n}\n\n.R9tl_ {\n  stroke: #ebeff2;\n}\n\n.YDrbh {\n  stroke: #e6e4e4;\n}\n\n.VtrCU {\n  overflow: hidden;\n  color: var(--wpp-grey-color-000);\n}\n\n.kx9ix {\n  margin: auto;\n  display: table;\n  padding-top: 7px;\n}", "",{"version":3,"sources":["webpack://./src/components/gantt/src/components/grid/grid.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".gridRow {\n  fill: #fff;\n}\n\n.gridRow:nth-child(even) {\n  fill: #f5f5f5;\n}\n\n.gridRowLine {\n  stroke: #ebeff2;\n}\n\n.gridTick {\n  stroke: #e6e4e4;\n}\n\n.itemLabel {\n  overflow: hidden;\n  color: var(--wpp-grey-color-000);\n}\n\n.text {\n  margin: auto;\n  display: table;\n  padding-top: 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridRow": "b9R48",
	"gridRowLine": "R9tl_",
	"gridTick": "YDrbh",
	"itemLabel": "VtrCU",
	"text": "kx9ix"
};
export default ___CSS_LOADER_EXPORT___;
