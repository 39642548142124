import React, { useRef, useEffect } from 'react'

import styles from './gantt.module.css'
import { TaskGanttContentProps, TaskGanttContent } from './task-gantt-content'
import { CalendarProps, Calendar } from '../calendar/calendar'
import { GridProps, Grid } from '../grid/grid'

export interface TaskGanttProps {
  gridProps: GridProps
  calendarProps: CalendarProps
  barProps: TaskGanttContentProps
  ganttHeight: number
  ganttFullHeight: number
  scrollY: number
  scrollX: number
}
export const TaskGantt: React.FC<TaskGanttProps> = ({
  gridProps,
  calendarProps,
  barProps,
  ganttHeight,
  ganttFullHeight,
  scrollY,
  scrollX,
}) => {
  const ganttSVGRef = useRef<SVGSVGElement>(null)
  const horizontalContainerRef = useRef<HTMLDivElement>(null)
  const verticalGanttContainerRef = useRef<HTMLDivElement>(null)
  const newBarProps = { ...barProps, svg: ganttSVGRef }

  useEffect(() => {
    if (horizontalContainerRef.current) {
      horizontalContainerRef.current.scrollTop = scrollY
    }
  }, [scrollY])

  useEffect(() => {
    if (verticalGanttContainerRef.current) {
      verticalGanttContainerRef.current.scrollLeft = scrollX
    }
  }, [scrollX])

  return (
    <div className={styles.ganttVerticalContainer} ref={verticalGanttContainerRef} dir="ltr">
      <svg xmlns="http://www.w3.org/2000/svg" width={gridProps.svgWidth} height={92} fontFamily={barProps.fontFamily}>
        <Calendar {...calendarProps} />
      </svg>
      <div
        ref={horizontalContainerRef}
        className={styles.horizontalContainer}
        style={ganttHeight ? { height: ganttHeight, width: gridProps.svgWidth } : { width: gridProps.svgWidth }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={gridProps.svgWidth}
          height={ganttFullHeight}
          fontFamily={barProps.fontFamily}
          ref={ganttSVGRef}
        >
          <Grid {...gridProps} />
          <TaskGanttContent {...newBarProps} />
        </svg>
      </div>
    </div>
  )
}
