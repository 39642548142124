import { WppIconTrash, WppTypography } from '@platform-ui-kit/components-library-react'
import Marquee from 'react-fast-marquee'

import style from './bar.module.scss'
import { useAdminContext } from '../../../../../../app/AdminContext'
import { DeletePopover } from '../../../../../popovers/DeletePopover'
import { BarTask } from '../../../types/bar-task'
import { Task, TimeObject } from '../../../types/public-types'
import { defaultTheme } from '../../gantt/gantt'
import { Milestone } from '../milestone/milestone'
import { ParityMilestone } from '../milestone/parity-milestone'

export interface AddItemModalProps {
  setModalItem: (item: TimeObject | undefined) => void
  setModalItemName: (itemName: string) => void
  setModalBenefit: (benefit: Task) => void
  setIsModalOpened: (isModalOpened: boolean) => void
}

interface BarDisplayProps {
  task: BarTask
  isSelected: boolean
  onMouseDown: (event: React.MouseEvent<SVGPolygonElement, MouseEvent>) => void
  modalProps: AddItemModalProps
}
export const BarDisplay: React.FC<BarDisplayProps> = ({ task, isSelected, onMouseDown, modalProps }) => {
  const isAdmin = useAdminContext()
  const x = task.x1
  const y = task.y
  const height = (defaultTheme.rowHeight * defaultTheme.barFill) / 100
  const isSelectedOffset = isSelected ? (task.features.length + (isAdmin ? 1 : 0)) * 32 : 0

  const handleOpenModal = (t: TimeObject | undefined, taskName: string) => {
    modalProps.setModalItem(t)
    modalProps.setModalItemName(taskName)
    modalProps.setModalBenefit(task)
    modalProps.setIsModalOpened(true)
  }

  const featOrProj = (
    index: number,
    x: number,
    y: number,
    width: number,
    text: string,
    task: TimeObject | undefined = undefined,
    isProduct: boolean = false,
  ) => {
    let fill = index === -1 ? defaultTheme.newFeatureColor : defaultTheme.featureColor
    let stroke = defaultTheme.featureStrokeColor
    if (isProduct) {
      fill = index === -1 ? defaultTheme.newProductColor : defaultTheme.productColor
      stroke = defaultTheme.productStrokeColor
    }

    return (
      <>
        <rect
          x={x}
          width={width}
          y={y}
          height={28}
          fill={fill}
          stroke={stroke}
          strokeWidth={1}
          strokeDasharray="2,2"
          rx={8}
        />
        <foreignObject x={x} y={y} width={width} height={28}>
          <div className={style.container}>
            {index !== -1 ? (
              <Marquee speed={10} gradientWidth={20}>
                <WppTypography
                  type="s-body"
                  tag="span"
                  onClick={() => handleOpenModal(task, isProduct ? 'Product' : 'Feature')}
                >
                  {text}
                </WppTypography>
              </Marquee>
            ) : (
              <WppTypography
                type="s-body"
                tag="span"
                onClick={() => handleOpenModal(task, isProduct ? 'Product' : 'Feature')}
              >
                {text}
              </WppTypography>
            )}

            {index !== -1 && task && isAdmin && (
              <DeletePopover name={isProduct ? 'Product' : 'Feature'} obj={task}>
                <WppIconTrash height={20} width={20} className={style.icon} />
              </DeletePopover>
            )}
          </div>
        </foreignObject>
      </>
    )
  }

  return (
    <g onMouseDown={onMouseDown}>
      {isSelected && (
        <>
          {isAdmin && featOrProj(-1, x + 50, y, 200, 'Add New Feature')}
          {task.featuresX.length > 0 && (
            <>
              {task.featuresX.map((feature, index) => (
                <g key={index}>
                  {featOrProj(
                    index,
                    feature.x1,
                    y + (index + (isAdmin ? 1 : 0)) * 32,
                    feature.x2 - feature.x1,
                    task.features[index].name,
                    task.features[index],
                  )}
                </g>
              ))}
            </>
          )}
        </>
      )}

      <rect
        x={x - 133}
        width={145}
        y={y + isSelectedOffset}
        height={height}
        fill={defaultTheme.barColor}
        className={style.barBackground}
        rx={8}
      />

      {isSelected && isAdmin && (
        <>
          <rect
            x={x - 133}
            width={139}
            y={y + isSelectedOffset + height + 4}
            height={28}
            fill="var(--wpp-danger-color-500)"
            rx={8}
          />
          <foreignObject x={x - 133} y={y + isSelectedOffset + height + 4} width={139} height={28}>
            <div className={style.delete}>
              <DeletePopover name="Benefit" obj={task}>
                <WppTypography type="s-strong" tag="span" className={style.deleteText}>
                  Delete Benefit
                </WppTypography>
              </DeletePopover>
            </div>
          </foreignObject>
        </>
      )}

      {task.milestones.convergence.length > 0 && (
        <>
          <rect
            x={x}
            width={Math.max(...task.milestones.convergence.map(o => o.x2)) - task.x1 + 6}
            y={y + isSelectedOffset}
            height={28}
            fill="var(--wpp-dataviz-color-cat-light-9)"
            className={style.smallBar}
            rx={8}
          />
          {task.milestones.convergence.map(milestone => (
            <Milestone
              x={milestone.x2}
              y={y + 7 + isSelectedOffset}
              height={10}
              key={milestone.x1}
              text={milestone.name}
            />
          ))}
        </>
      )}
      {task.milestones.adoption.length > 0 && (
        <>
          <rect
            x={x}
            width={Math.max(...task.milestones.adoption.map(o => o.x2)) - task.x1 + 6}
            y={y + 32 + isSelectedOffset}
            height={28}
            fill="var(--wpp-dataviz-color-cat-light-1)"
            className={style.smallBar}
            rx={8}
          />
          {task.milestones.adoption.map(milestone => (
            <Milestone
              x={milestone.x2}
              y={y + 39 + isSelectedOffset}
              height={10}
              key={milestone.x1}
              text={milestone.name}
            />
          ))}
        </>
      )}
      {task.milestones.migration.length > 0 && (
        <>
          <rect
            x={x}
            width={Math.max(...task.milestones.migration.map(o => o.x2)) - task.x1 + 6}
            y={y + 64 + isSelectedOffset}
            height={28}
            fill="var(--wpp-dataviz-color-cat-light-8)"
            className={style.smallBar}
            rx={8}
          />
          {task.milestones.migration.map(milestone => (
            <Milestone
              x={milestone.x2}
              y={y + 71 + isSelectedOffset}
              height={10}
              key={milestone.x1}
              text={milestone.name}
            />
          ))}
        </>
      )}
      {task.paritiesX.length > 0 && (
        <>
          {task.paritiesX.map(milestone => (
            <ParityMilestone
              x={milestone.x1}
              y={y + isSelectedOffset}
              height={height}
              key={milestone.x1}
              text={milestone.name}
            />
          ))}
        </>
      )}
      {isSelected && (
        <>
          {task.productsX.length > 0 && (
            <>
              {task.productsX.map((product, index) => (
                <g key={index}>
                  {featOrProj(
                    index,
                    product.x1,
                    y + 96 + isSelectedOffset + index * 32,
                    product.x2 - product.x1,
                    task.products[index].name,
                    task.products[index],
                    true,
                  )}
                </g>
              ))}
            </>
          )}
          {isAdmin &&
            featOrProj(
              -1,
              x + 50,
              y + 96 + isSelectedOffset + task.productsX?.length * 32,
              200,
              'Add New Product',
              undefined,
              true,
            )}
        </>
      )}
    </g>
  )
}
