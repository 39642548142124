// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IwnfB {\n  overflow: hidden;\n  font-size: 0;\n  margin: 0;\n  padding: 0;\n}\n\n._Fg9c {\n  margin: 0;\n  padding: 0;\n  overflow: hidden;\n}\n\n.itF4U {\n  display: flex;\n  padding: 0;\n  margin: 0;\n  list-style: none;\n  outline: none;\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/components/gantt/src/components/gantt/gantt.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,UAAU;EACV,SAAS;EACT,gBAAgB;EAChB,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".ganttVerticalContainer {\n  overflow: hidden;\n  font-size: 0;\n  margin: 0;\n  padding: 0;\n}\n\n.horizontalContainer {\n  margin: 0;\n  padding: 0;\n  overflow: hidden;\n}\n\n.wrapper {\n  display: flex;\n  padding: 0;\n  margin: 0;\n  list-style: none;\n  outline: none;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ganttVerticalContainer": "IwnfB",
	"horizontalContainer": "_Fg9c",
	"wrapper": "itF4U"
};
export default ___CSS_LOADER_EXPORT___;
