import { AddItemModalProps } from '../components/task-item/bar/bar-display'
import { UpdateBenefitModalProps } from '../components/task-item/task-item'

export enum ViewMode {
  Hour = 'Hour',
  QuarterDay = 'Quarter Day',
  HalfDay = 'Half Day',
  Day = 'Day',
  /** ISO-8601 week */
  Week = 'Week',
  Month = 'Month',
  QuarterYear = 'QuarterYear',
  Year = 'Year',
}

export interface Check {
  check: boolean
  name: string
  id: string
}

export interface ItemDetails {
  description?: string
  checks?: Check[]
}

export interface TimeObject extends ItemDetails {
  id?: string
  name: string
  start: Date
  end: Date
}

export interface Parity {
  id?: string
  name: string
  date: Date
}

export interface Task extends TimeObject {
  convergenMilestone: TimeObject[]
  adoptionMilestone: TimeObject[]
  migrationMilestone: TimeObject[]
  parities: Parity[]
  features: TimeObject[]
  products: TimeObject[]
  isDisabled?: boolean
  displayOrder?: number
}

export interface TimeObjectWithNoDates extends ItemDetails {
  id?: string
  name: string
  start?: Date
  end?: Date
}

export interface TaskWithNoDates extends TimeObjectWithNoDates {
  convergenMilestone: TimeObject[]
  adoptionMilestone: TimeObject[]
  migrationMilestone: TimeObject[]
  parities: Parity[]
  features: TimeObject[]
  products: TimeObject[]
  isDisabled?: boolean
  displayOrder?: number
}

export interface EventOption {
  /**
   * Time step value for date changes.
   */
  timeStep?: number
  /**
   * Invokes on bar select on unselect.
   */
  onSelect?: (task: Task, isSelected: boolean) => void
  /**
   * Invokes on bar double click.
   */
  onDoubleClick?: (task: Task) => void
  /**
   * Invokes on bar click.
   */
  onClick?: (task: Task) => void
  /**
   * Invokes on end and start time change. Chart undoes operation if method return false or error.
   */
  onDateChange?: (task: Task) => void | boolean | Promise<void> | Promise<boolean>
  /**
   * Invokes on progress change. Chart undoes operation if method return false or error.
   */
  onProgressChange?: (task: Task) => void | boolean | Promise<void> | Promise<boolean>
  /**
   * Invokes on delete selected task. Chart undoes operation if method return false or error.
   */
  onDelete?: (task: Task) => void | boolean | Promise<void> | Promise<boolean>
  /**
   * Invokes on expander on task list
   */
  onExpanderClick?: (task: Task) => void
}

export interface DisplayOption {
  viewMode?: ViewMode
  viewDate?: Date
  preStepsCount?: number
  /**
   * Specifies the month name language. Able formats: ISO 639-2, Java Locale
   */
  locale?: string
  rtl?: boolean
}

export interface StylingOption {
  headerHeight?: number
  columnWidth?: number
  listCellWidth?: string
  rowHeight?: number
  ganttHeight?: number
  barCornerRadius?: number
  handleWidth?: number
  fontFamily?: string
  fontSize?: number
  /**
   * How many of row width can be taken by task.
   * From 0 to 100
   */
  barFill?: number
  barProgressColor?: string
  barProgressSelectedColor?: string
  barBackgroundColor?: string
  barBackgroundSelectedColor?: string
  projectProgressColor?: string
  projectProgressSelectedColor?: string
  projectBackgroundColor?: string
  projectBackgroundSelectedColor?: string
  milestoneBackgroundColor?: string
  milestoneBackgroundSelectedColor?: string
  arrowColor?: string
  arrowIndent?: number
  todayColor?: string
  TooltipContent?: React.FC<{
    task: Task
    fontSize: number
    fontFamily: string
  }>
  TaskListHeader?: React.FC<{
    headerHeight: number
    rowWidth: string
    fontFamily: string
    fontSize: number
  }>
  TaskListTable?: React.FC<{
    rowHeight: number
    rowWidth: string
    fontFamily: string
    fontSize: number
    locale: string
    tasks: Task[]
    selectedTaskId: string
    /**
     * Sets selected task by id
     */
    setSelectedTask: (taskId: string) => void
    onExpanderClick: (task: Task) => void
  }>
}

export interface GanttProps extends EventOption, DisplayOption, StylingOption {
  tasks: Task[]
  modalProps: AddItemModalProps
  updateBenefitModalProps: UpdateBenefitModalProps
}
